import { type BigIntString, toBigIntString } from '../../notebook/gen/runtime';
import type { ConstraintTypeMapAttributes, Parser, ValueTimeUnitInfoPair } from './components/types';
import {
  ConstraintBasicType,
  ConstraintType,
  DAY_SECONDS,
  GIBIBYTE,
  HOUR_SECONDS,
  MONTH_SECONDS,
  TimeUnit,
  WEEK_SECONDS,
  YEAR_SECONDS,
  maxBytes,
  maxSeconds,
  suffix,
  timeUnitInfoMap,
} from './constants';

export const getIdFromName = (value: string | null = null) => (!value ? null : value.split('/').reverse()[0]) || '';
// This is the valid regex for it: ^[a-z]([a-z0-9-]{0,61}[a-z0-9])?$
//  source (after make generate): gen/openapiv2/ai/h2o/engine/v1/dai_engine_service.swagger.json:354
export const getRandomNumber = () => Math.floor(Math.random() * 10000);
export const getVariant = (last?: number, candidate = getRandomNumber()): number => {
  return !last || last !== candidate ? candidate : getVariant(candidate);
};
export const extractId = (value: string) =>
  value
    .toLowerCase() // set everything to lowercase
    .replace(/\s+/g, '-') // replace spaces by dashes
    // deletes all non-alphabetical until first alphabetical found character
    // deletes all hyphens and non-alphanumerical characters
    .replace(/^[^a-z]+|[^\w-]|[-]{2,}/g, '')
    .replace(/[-]$/g, ''); // delete last non-alphanumeric character

export const tryName = (value: string, lastVariant?: number): { id: string; variant: number } => ({
  id: extractId(value),
  variant: lastVariant ? getVariant(lastVariant) : getRandomNumber(),
});
export const concatId = (value: string, variant?: number) => Object.values(tryName(value, variant)).join('-');

export const gibibytesToBytes = (value: string | number | null | undefined): string | undefined => {
  const number = Number(value);
  if (isNaN(number)) {
    return undefined;
  }
  return (number * GIBIBYTE).toString();
};
export const bytesToGibibytes = (value: string | number | null | undefined): string | undefined => {
  const number = Number(value);
  if (!value || isNaN(number)) {
    return undefined;
  }
  return Math.floor(number / GIBIBYTE).toString();
};
export const gibibytesToBigIntStringBytes = (value: number): BigIntString | undefined => {
  const bytes = gibibytesToBytes(value);
  if (bytes === undefined) return undefined;
  return toBigIntString(bytes);
};
export const secondsToHours = (value: string | number | null | undefined): string | undefined => {
  const number = typeof value === 'string' ? Number(value?.replace(/[a-zA-Z]/g, '')) : Number(value);
  if (isNaN(number)) {
    return undefined;
  }
  return Math.floor(number / HOUR_SECONDS).toString();
};
export const hoursToSeconds = (value: string | number | null | undefined): string | undefined => {
  const number = Number(value);
  if (isNaN(number)) {
    return undefined;
  }
  return `${(number * HOUR_SECONDS).toString()}s`;
};

export const parseNumberAndTimeUnitToSeconds = (number: number, unit: TimeUnit): string | undefined => {
  let seconds: number;
  if (!number) return undefined;
  switch (unit) {
    case TimeUnit.Minute:
      seconds = number * 60;
      break;
    case TimeUnit.Hour:
      seconds = number * HOUR_SECONDS;
      break;
    case TimeUnit.Day:
      seconds = number * DAY_SECONDS;
      break;
    case TimeUnit.Week:
      seconds = number * WEEK_SECONDS;
      break;
    case TimeUnit.Month:
      seconds = number * MONTH_SECONDS;
      break;
    case TimeUnit.Year:
      seconds = number * YEAR_SECONDS;
      break;
    case TimeUnit.Second:
    default:
      seconds = number;
      break;
  }
  return `${seconds}s`;
};
export const parseNumberToString: Parser = (value) => (value === null ? null : value?.toString());
export const parseStringToNumber: Parser = (value) => (typeof value !== 'string' ? value : parseInt(value, 10));
export const parseSeconds = (value: string | number | null | undefined): number => {
  return typeof value === 'string' ? Number(value?.replace(/[a-zA-Z]/g, '')) : Number(value);
};
export const getBestDateTimeUnitInfoPair = (value: string | number | null | undefined): ValueTimeUnitInfoPair => {
  const seconds = parseSeconds(value);
  if (isNaN(seconds)) {
    return [0, undefined];
  }
  switch (true) {
    case seconds < 60:
      return [seconds, timeUnitInfoMap[TimeUnit.Second]];
    case seconds < HOUR_SECONDS:
      return [Math.floor(seconds / 60), timeUnitInfoMap[TimeUnit.Minute]];
    case seconds < DAY_SECONDS:
      return [Math.floor(seconds / HOUR_SECONDS), timeUnitInfoMap[TimeUnit.Hour]];
    case seconds < WEEK_SECONDS:
      return [Math.floor(seconds / DAY_SECONDS), timeUnitInfoMap[TimeUnit.Day]];
    case seconds < MONTH_SECONDS:
      return [Math.floor(seconds / WEEK_SECONDS), timeUnitInfoMap[TimeUnit.Week]];
    case seconds < YEAR_SECONDS:
      return [Math.floor(seconds / MONTH_SECONDS), timeUnitInfoMap[TimeUnit.Month]];
    default:
      return [Math.floor(seconds / YEAR_SECONDS), timeUnitInfoMap[TimeUnit.Year]];
  }
};
export const parseToBytesAndBigIntString: Parser = (value) => toBigIntString(Number(gibibytesToBytes(value)));
export const parseBytesToGibibytes: Parser = (value) => bytesToGibibytes(value);
export const parseSecondsToHours: Parser = (value) => secondsToHours(value);
export const parseHoursToSeconds: Parser = (value) => hoursToSeconds(value);

export const constraintTypeMapAttributes: { [key in ConstraintType as string]: ConstraintTypeMapAttributes } = {
  [ConstraintType.CPU]: {
    FromView: parseNumberToString,
    ToView: parseStringToNumber,
    basicType: ConstraintBasicType.NUMERIC,
    min: 1,
  },
  [ConstraintType.GPU]: {
    FromView: parseNumberToString,
    ToView: parseStringToNumber,
    basicType: ConstraintBasicType.NUMERIC,
  },
  [ConstraintType.MAXIDLEDURATION]: {
    FromView: parseHoursToSeconds,
    ToView: parseSecondsToHours,
    basicType: ConstraintBasicType.TIME,
    max: maxSeconds,
  },
  [ConstraintType.MAXRUNNINGDURATION]: {
    FromView: parseHoursToSeconds,
    ToView: parseSecondsToHours,
    basicType: ConstraintBasicType.TIME,
    max: maxSeconds,
  },
  [ConstraintType.MEMORYBYTES]: {
    FromView: parseToBytesAndBigIntString,
    ToView: parseBytesToGibibytes,
    suffix,
    basicType: ConstraintBasicType.BYTES,
    max: maxBytes,
    min: 1,
  },
  [ConstraintType.STORAGEBYTES]: {
    FromView: parseToBytesAndBigIntString,
    ToView: parseBytesToGibibytes,
    suffix,
    basicType: ConstraintBasicType.BYTES,
    max: maxBytes,
    min: 1,
  },
  [ConstraintType.NODECOUNT]: {
    basicType: ConstraintBasicType.NUMERIC,
    min: 1,
  },
};
