import { Stack } from '@fluentui/react';
import { type IPivotItem, PageHeader, Pivot } from '@h2oai/ui-kit';

import { OAuthClientEntity } from '../../secure-store/entity/OAuthClientEntity';
import { OAuthClient } from '../../secure-store/gen/ai/h2o/securestore/v1/oauth_client_pb';
import { stackStylesNoLeftNav } from '../../themes/themes';
import { SecureStoreEntityDataContainer } from './components/SecureStoreEntityDataContainer';

export default function SecureStoreAdminPage() {
  const items: IPivotItem[] = [
    {
      key: `pivot-item-content-oauth-client`,
      headerText: 'OAuth Client',
      content: <SecureStoreEntityDataContainer<OAuthClient> entity={OAuthClientEntity} />,
    },
  ];

  return (
    <Stack styles={stackStylesNoLeftNav}>
      <Stack horizontal horizontalAlign="space-between" style={{ marginBottom: '20px' }}>
        <Stack.Item disableShrink>
          <PageHeader pageTitle="OAuth Clients" />
        </Stack.Item>
      </Stack>

      <Pivot items={items} />
    </Stack>
  );
}
