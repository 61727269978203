import type { Entity } from '../../components/AdminSettings/Entity/types';
import { kernelImageEntity } from './KernelImage.entity';
import { kernelSpecEntity } from './KernelSpec.entity';
import { kernelTemplateEntity } from './KernelTemplate.entity';
import { NotebookKernelEntityType } from './types';

export const NotebookEntitiesMap = new Map<NotebookKernelEntityType, Entity<any, NotebookKernelEntityType>>([
  [NotebookKernelEntityType.KernelImage, kernelImageEntity],
  [NotebookKernelEntityType.KernelTemplate, kernelTemplateEntity],
  [NotebookKernelEntityType.KernelSpec, kernelSpecEntity],
]);
