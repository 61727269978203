import { IStyle, Stack } from '@fluentui/react';
import { useClassNames } from '@h2oai/ui-kit';
import React from 'react';

import { stackStylesPage } from '../../themes/themes';
import { ClassNamesFromIStyles } from '../../utils/models';

interface IPageWrapperStyles {
  container: IStyle;
  stack: IStyle;
}

const pageWrapperStyles = (): Partial<IPageWrapperStyles> => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  stack: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
  },
});

const PageWrapper: React.FC = ({ children }) => {
  const classNames = useClassNames<IPageWrapperStyles, ClassNamesFromIStyles<IPageWrapperStyles>>(
    'pageWrapper',
    pageWrapperStyles
  );

  return (
    <div className={classNames.container}>
      <Stack styles={stackStylesPage} className={classNames.stack}>
        {children}
      </Stack>
    </div>
  );
};

export default PageWrapper;
