import { type IColumn, IDropdownOption } from '@fluentui/react';
import { ITextWithCopyStyles, TextWithCopy, textWithCopyStylesPlain } from '@h2oai/ui-kit';

import { bytesToGibibytes } from '../../../aiem/utils';
import { kernelImageTypeMap } from '../../../notebook/constants';
import type { KernelImage } from '../../../notebook/gen/ai/h2o/notebook/v1/kernel_image_pb';
import { MetadataCell } from '../../ListPages/MetadataCell';

export const getOptionsFromEnum = (
  enumObject: any,
  suppressingText = '',
  exceptionList: string[] = []
): IDropdownOption[] => {
  const options: IDropdownOption[] = [];

  for (const enumkey in enumObject) {
    if (Object.prototype.hasOwnProperty.call(enumObject, enumkey)) {
      if (exceptionList.includes(enumObject[enumkey])) continue;
      const key = enumObject[enumkey],
        text = String(enumObject[enumkey])
          .replaceAll(suppressingText || '', '')
          .toLowerCase()
          .split('_')
          .map((word) => {
            const firstLetter = word[0];
            return firstLetter.toUpperCase() + word.slice(1);
          })
          .join(' ');
      options.push({ key, text });
    }
  }
  return options;
};

export const copyHideContentsStyles: ITextWithCopyStyles = {
  ...textWithCopyStylesPlain,
  contents: { display: 'none' },
};

export const onRenderDisplayName = <EntityModel,>(item: EntityModel, _?: number, column?: IColumn) => (
  <MetadataCell title={column?.fieldName ? item[column?.fieldName] : column?.name}>
    <TextWithCopy
      text={item[column?.key || 'name']}
      header={getResourceId(item[column?.key || 'name'])}
      styles={copyHideContentsStyles}
    />
  </MetadataCell>
);

export const onRenderKernelImageType = (item: KernelImage, _?: number, column?: IColumn) => (
  <MetadataCell title={column?.name}>{kernelImageTypeMap[item?.type || '']}</MetadataCell>
);

export const bytesToGiBWithPostfix = (bytes: number | string = ''): string => `${bytesToGibibytes(bytes)}GiB`;

export const checkForPrefix = (name?: string, prefix?: string) =>
  !name || !prefix ? name : name.startsWith(prefix) ? name : `${prefix}${name}`;

export const getResourceId = (resource?: string | undefined) => resource?.split('/').pop();

export const booleanToReadable = (value?: boolean) => (value ? 'Yes' : 'No');
