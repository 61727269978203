import type { TimeUnitInfo } from './components/types';

export enum TimeUnit {
  Second = 'Second',
  Minute = 'Minute',
  Hour = 'Hour',
  Day = 'Day',
  Week = 'Week',
  Month = 'Month',
  Year = 'Year',
}

export enum ConstraintType {
  CPU = 'cpu',
  GPU = 'gpu',
  MAXIDLEDURATION = 'maxIdleDuration',
  MAXRUNNINGDURATION = 'maxRunningDuration',
  MEMORYBYTES = 'memoryBytes',
  NODECOUNT = 'nodeCount',
  STORAGEBYTES = 'storageBytes',
}

export enum ConstraintBasicType {
  NUMERIC,
  TIME,
  BYTES,
}

export const maxSeconds = 259200;
export const maxBytes = 1_000_000_000_000;
export const suffix = 'GiB';

export const timeUnitInfoMap: { [key in TimeUnit]: TimeUnitInfo } = {
  [TimeUnit.Second]: {
    key: 'seconds',
    text: 'Seconds',
    singular: 'second',
    plural: 'seconds',
    type: TimeUnit.Second,
  },
  [TimeUnit.Minute]: {
    key: 'minutes',
    text: 'Minutes',
    singular: 'minute',
    plural: 'minutes',
    type: TimeUnit.Minute,
  },
  [TimeUnit.Hour]: {
    key: 'seconds',
    text: 'Hours',
    singular: 'hour',
    plural: 'hours',
    type: TimeUnit.Hour,
  },
  [TimeUnit.Day]: {
    key: 'days',
    text: 'Days',
    singular: 'day',
    plural: 'days',
    type: TimeUnit.Day,
  },
  [TimeUnit.Week]: {
    key: 'weeks',
    text: 'Weeks',
    singular: 'week',
    plural: 'weeks',
    type: TimeUnit.Week,
  },
  [TimeUnit.Month]: {
    key: 'months',
    text: 'Months',
    singular: 'month',
    plural: 'months',
    type: TimeUnit.Month,
  },
  [TimeUnit.Year]: {
    key: 'years',
    text: 'Years',
    singular: 'year',
    plural: 'years',
    type: TimeUnit.Year,
  },
};

export const GIBIBYTE = 1_073_741_824;
export const HOUR_SECONDS = 3_600;
export const DAY_SECONDS = 86_400;
export const WEEK_SECONDS = 604_800;
export const MONTH_SECONDS = 2_592_000;
export const YEAR_SECONDS = 31_536_000;
