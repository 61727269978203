import { Stack } from '@fluentui/react';
import { useTheme } from '@h2oai/ui-kit';
import { useState } from 'react';
import { parse, stringify } from 'yaml';

import { CodeArea } from '../../CodeArea/CodeArea';
import type { EntityFieldInputProps } from './BasicEntityModelComponents/types';
import { defaultEntityFormRowStyles, defaultEntityLabelColumnWidth } from './DefaultEntityFormRowStyles';
import { LabelAndDescription } from './LabelAndDescription';

export const YamlEntityModelField = <EntityModel, EntityType extends string>(
  props: EntityFieldInputProps<EntityModel, EntityType>
) => {
  const { field, model, onChange } = props;
  const { name, label, description, referenceName } = field;
  const initialValue = (model as any)[referenceName || name] as string;
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState<string | undefined>(undefined);
  const { semanticColors } = useTheme();

  return (
    <Stack tokens={{ childrenGap: 15 }} styles={{ root: defaultEntityFormRowStyles }}>
      <Stack styles={{ root: { minWidth: defaultEntityLabelColumnWidth, maxWidth: defaultEntityLabelColumnWidth } }}>
        <LabelAndDescription light label={label} description={description} required={field.required} />
        {error && (
          <Stack
            styles={{
              root: {
                padding: 10,
                borderRadius: 5,
                color: 'white',
                opacity: 0.7,
                backgroundColor: semanticColors?.buttonDanger,
                borderColor: semanticColors?.buttonDangerActiveBorder,
              },
            }}
          >
            {error}
          </Stack>
        )}
      </Stack>
      <CodeArea
        cols={30}
        rows={21}
        defaultValue={value}
        onChange={({ target }) => {
          const v = target?.value;
          setValue(v);
          try {
            const parsedData = parse(v);
            setError(undefined);
            onChange!(referenceName || name, stringify(parsedData));
          } catch (_e: any) {
            setError(_e.message);
          }
        }}
      />
    </Stack>
  );
};
