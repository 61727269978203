import { PanelType, Stack, Text } from '@fluentui/react';
import { Button, Panel, TextWithCopy } from '@h2oai/ui-kit';
import { useMemo } from 'react';

import { renderViewFieldElement } from '../../../components/AdminSettings/components/SidePannel/utils';
import {
  adminSettingsViewPanelLabel,
  adminSettingsViewPanelValue,
} from '../../../components/AdminSettings/components/SidePannel/viewPannel.styles';
import { booleanToReadable } from '../../../components/AdminSettings/Entity/utils';
import { getIdFromName } from '../../../components/AdminSettings/utils';
import { stylesPanel } from '../../../components/AIEnginesPage/components/AIEMPanel/AIEMPanel';
import { SecureStoreEntityType } from '../../../secure-store/entity/types';
import type { OAuthClient } from '../../../secure-store/gen/ai/h2o/securestore/v1/oauth_client_pb';
import { SecureStoreViewPanelProps } from './types';

const OAuthClientEntityView = (oauthClient?: OAuthClient) => {
  if (!oauthClient) {
    return <></>;
  }

  return (
    <>
      <Stack horizontal>
        <Text {...adminSettingsViewPanelLabel}>Display name</Text>
        <Text {...adminSettingsViewPanelValue}>{oauthClient.displayName}</Text>
      </Stack>
      <Stack horizontal>
        <Text {...adminSettingsViewPanelLabel}>ID</Text>
        <Text {...adminSettingsViewPanelValue}>{getIdFromName(oauthClient.name || '')}</Text>
      </Stack>
      <Stack horizontal>
        <Text {...adminSettingsViewPanelLabel}>Issuer</Text>
        <Text {...adminSettingsViewPanelValue} title={oauthClient.issuer}>
          {renderViewFieldElement(oauthClient.issuer, <TextWithCopy text={oauthClient.issuer} />)}
        </Text>
      </Stack>
      <Stack horizontal>
        <Text {...adminSettingsViewPanelLabel}>ClientID</Text>
        <Text {...adminSettingsViewPanelValue} title={oauthClient.clientId}>
          {renderViewFieldElement(oauthClient.clientId, <TextWithCopy text={oauthClient.clientId} />)}
        </Text>
      </Stack>
      <Stack horizontal>
        <Text {...adminSettingsViewPanelLabel}>Client Secret</Text>
        <Text {...adminSettingsViewPanelValue} title={oauthClient.clientSecret}>
          {renderViewFieldElement(oauthClient.clientSecret, <TextWithCopy text={oauthClient.clientSecret} />)}
        </Text>
      </Stack>
      <Stack horizontal>
        <Text {...adminSettingsViewPanelLabel}>Authorization URL</Text>
        <Text {...adminSettingsViewPanelValue} title={oauthClient.authorizationEndpoint}>
          {renderViewFieldElement(
            oauthClient.authorizationEndpoint,
            <TextWithCopy text={oauthClient.authorizationEndpoint} />
          )}
        </Text>
      </Stack>
      <Stack horizontal>
        <Text {...adminSettingsViewPanelLabel}>Token Endpoint</Text>
        <Text {...adminSettingsViewPanelValue} title={oauthClient.tokenEndpoint}>
          {renderViewFieldElement(oauthClient.tokenEndpoint, <TextWithCopy text={oauthClient.tokenEndpoint} />)}
        </Text>
      </Stack>
      <Stack horizontal>
        <Text {...adminSettingsViewPanelLabel}>Login Principal Claim</Text>
        <Text {...adminSettingsViewPanelValue} title={oauthClient.loginPrincipalClaim}>
          {renderViewFieldElement(
            oauthClient.loginPrincipalClaim,
            <TextWithCopy text={oauthClient.loginPrincipalClaim} />
          )}
        </Text>
      </Stack>
      <Stack horizontal>
        <Text {...adminSettingsViewPanelLabel}>Extra Scopes</Text>
        <Text {...adminSettingsViewPanelValue}>{oauthClient.extraScopes?.join(', ') || '-'}</Text>
      </Stack>
      <Stack horizontal>
        <Text {...adminSettingsViewPanelLabel}>Refresh Disabled</Text>
        <Text {...adminSettingsViewPanelValue}>{booleanToReadable(oauthClient.refreshDisabled)}</Text>
      </Stack>
    </>
  );
};

export const SecureStoreViewPanel = (props: SecureStoreViewPanelProps) => {
  const { item, type, onDismiss, panelTitle } = props;
  const content = useMemo(() => {
    switch (type) {
      case SecureStoreEntityType.OAuthClient:
        return OAuthClientEntityView(item);

      default:
        return null;
    }
  }, [type, item]);

  return (
    <Panel
      isLightDismiss
      customWidth="500px"
      headerText={panelTitle}
      isFooterAtBottom
      isOpen={true}
      onDismiss={onDismiss}
      type={PanelType.custom}
      styles={stylesPanel}
      onRenderFooterContent={() => {
        return (
          <Stack horizontal tokens={{ childrenGap: 10 }} horizontalAlign="end">
            <Button text="Close" onClick={onDismiss} />
          </Stack>
        );
      }}
    >
      <Stack tokens={{ childrenGap: 20 }}>{content}</Stack>
    </Panel>
  );
};
