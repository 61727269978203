export interface SemverValidationState {
  available: boolean;
  valid: boolean;
  isMinimum: boolean;
}

export enum SemverValidationAction {
  AVAILABLE = 'available',
  VALID = 'valid',
  IS_MINIMUM = 'isMinimum',
}

export type SemverValidationActions =
  | { type: SemverValidationAction.AVAILABLE; value: boolean }
  | { type: SemverValidationAction.VALID; value: boolean }
  | { type: SemverValidationAction.IS_MINIMUM; value: boolean };

export type SemverValidationReducerFunction = (
  state: SemverValidationState,
  action: SemverValidationActions
) => SemverValidationState;
