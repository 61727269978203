import { type IColumn } from '@fluentui/react';

import { EntityActionType, EntityFieldType } from '../../components/AdminSettings/Entity/constants';
import type { Entity, EntityAction, EntityField } from '../../components/AdminSettings/Entity/types';
import { onRenderDisplayName } from '../../components/AdminSettings/Entity/utils';
import { onRenderDataCell } from '../../utils/render';
import { OAuthClient } from '../gen/ai/h2o/securestore/v1/oauth_client_pb';
import {
  CreateOAuthClientRequest,
  CreateOAuthClientResponse,
  DeleteOAuthClientRequest,
  DeleteOAuthClientResponse,
  GetOAuthClientRequest,
  GetOAuthClientResponse,
  ListOAuthClientsRequest,
  ListOAuthClientsResponse,
  OAuthClientService_CreateOAuthClient,
  OAuthClientService_DeleteOAuthClient,
  OAuthClientService_GetOAuthClient,
  OAuthClientService_ListOAuthClients,
  OAuthClientService_UpdateOAuthClient,
  UpdateOAuthClientRequest,
  UpdateOAuthClientResponse,
} from '../gen/ai/h2o/securestore/v1/oauth_client_service_pb';
import { oauthClientsResponseMock } from './mocks/responses.mock';
import { SecureStoreEntityType } from './types';

const entityType = SecureStoreEntityType.OAuthClient;
const required = true;

export const actions = {
  [EntityActionType.Create]: {
    name: 'CreateOAuthClient',
    rpc: OAuthClientService_CreateOAuthClient,
    type: EntityActionType.Create,
    mockResponse: oauthClientsResponseMock[0],
    responseKey: 'oauthClient',
    requestNameKey: 'oauthClientId',
    requestPayloadKey: 'oauthClient',
    requestNameKeyPrefix: 'oauthClient/',
    mockPath: '/oauthClient',
    entityType,
  } as EntityAction<OAuthClient, SecureStoreEntityType, CreateOAuthClientRequest, CreateOAuthClientResponse>,
  [EntityActionType.Get]: {
    name: 'GetOAuthClient',
    rpc: OAuthClientService_GetOAuthClient,
    type: EntityActionType.Get,
    mockResponse: oauthClientsResponseMock[0],
    responseKey: 'oauthClient',
    requestNameKey: 'name',
    requestPayloadKey: 'name',
    requestNameKeyPrefix: 'oauthClient/',
    mockPath: '/oauthClient',
    entityType,
  } as EntityAction<OAuthClient, SecureStoreEntityType, GetOAuthClientRequest, GetOAuthClientResponse>,
  [EntityActionType.List]: {
    name: 'ListOAuthClient',
    rpc: OAuthClientService_ListOAuthClients,
    type: EntityActionType.List,
    mockResponse: oauthClientsResponseMock,
    responseKey: 'oauthClients',
    mockPath: '/oauthClients',
    entityType,
  } as EntityAction<OAuthClient, SecureStoreEntityType, ListOAuthClientsRequest, ListOAuthClientsResponse>,
  [EntityActionType.Update]: {
    name: 'UpdateOAuthClient',
    rpc: OAuthClientService_UpdateOAuthClient,
    type: EntityActionType.Update,
    mockResponse: oauthClientsResponseMock[0],
    responseKey: 'oauthClient',
    requestNameKey: 'name' as unknown,
    requestPayloadKey: 'oauthClient',
    mockPath: '/oauthClient',
    entityType,
  } as EntityAction<OAuthClient, SecureStoreEntityType, UpdateOAuthClientRequest, UpdateOAuthClientResponse>,
  [EntityActionType.Delete]: {
    name: 'DeleteOAuthClient',
    rpc: OAuthClientService_DeleteOAuthClient,
    type: EntityActionType.Delete,
    mockResponse: oauthClientsResponseMock[0],
    requestNameKeyPrefix: 'oauthClient/',
    mockPath: '/oauthClient',
    entityType,
  } as EntityAction<OAuthClient, SecureStoreEntityType, DeleteOAuthClientRequest, DeleteOAuthClientResponse>,
};

export const columns: IColumn[] = [
  {
    key: 'name',
    name: 'Name',
    fieldName: 'displayName',
    minWidth: 200,
    maxWidth: 350,
    onRender: onRenderDisplayName,
  },
  {
    key: 'issuer',
    name: 'Issuer',
    fieldName: 'issuer',
    minWidth: 230,
    maxWidth: 280,
    onRender: onRenderDataCell,
  },
  {
    key: 'clientId',
    name: 'Client ID',
    fieldName: 'clientId',
    minWidth: 230,
    maxWidth: 280,
    onRender: onRenderDataCell,
  },
  {
    key: 'actions',
    name: 'Actions',
    fieldName: 'actions',
    minWidth: 170,
    maxWidth: 170,
  },
];

export const fields: EntityField<OAuthClient>[] = [
  {
    name: 'name',
    type: EntityFieldType.IdOnDisplayAndId,
    label: 'Name',
    description: 'OAuth Client resource name.',
    required,
  },
  {
    name: 'displayName',
    type: EntityFieldType.DisplayOnDisplayAndId,
    label: 'Display Name',
    description: 'Human-readable name of OAuth Client',
    required,
  },
  {
    name: 'issuer',
    type: EntityFieldType.Text,
    label: 'Issuer',
    required,
  },
  {
    name: 'clientId',
    type: EntityFieldType.Text,
    label: 'ClientID',
    required,
  },
  {
    name: 'clientSecret',
    type: EntityFieldType.Text,
    label: 'Client Secret',
  },
  {
    name: 'callbackUri',
    type: EntityFieldType.Text,
    label: 'Authorization URL',
  },
  {
    name: 'tokenEndpoint',
    type: EntityFieldType.Text,
    label: 'Token Endpoint',
  },
  {
    name: 'loginPrincipalClaim',
    type: EntityFieldType.Text,
    label: 'Login Principal Claim',
  },
  {
    name: 'extraScopes',
    type: EntityFieldType.StringArray,
    label: 'Extra Scopes',
  },
  {
    name: 'refreshDisabled',
    type: EntityFieldType.Boolean,
    label: 'Refresh Disabled',
  },
];

export const OAuthClientEntity: Entity<OAuthClient, SecureStoreEntityType> = {
  type: entityType,
  name: 'OAuth Client',
  createButtonLabel: '',
  displayName: 'OAuth Client',
  displayNameKey: 'displayName',
  requestNameKeyPrefix: '',
  actions,
  columns,
  fields,
  emptyModel: {
    name: 'new-oauth-client',
    displayName: 'New Oauth Client',
    issuer: '',
    clientId: '',
    clientSecret: '',
    callbackUri: '',
    tokenEndpoint: '',
    loginPrincipalClaim: '',
    extraScopes: [],
    refreshDisabled: false,
  },
  canBeReordered: false,
};
