import { fetchWrapRPC } from '../services/api';
import { ListRolesRequest, RoleService_ListRoles } from './gen/ai/h2o/authorization/v1/role_api_pb';
import {
  CreateRoleBindingRequest,
  DeleteRoleBindingRequest,
  ListRoleBindingsRequest,
  RoleBindingService_CreateRoleBinding,
  RoleBindingService_DeleteRoleBinding,
  RoleBindingService_ListRoleBindings,
} from './gen/ai/h2o/authorization/v1/role_binding_api_pb';
import { ListUsersRequest, UserService_ListUsers } from './gen/ai/h2o/user/v1/user_api_pb';
import {
  CreateWorkspaceRequest,
  DeleteWorkspaceRequest,
  ListWorkspacesRequest,
  UndeleteWorkspaceRequest,
  WorkspaceService_CreateWorkspace,
  WorkspaceService_DeleteWorkspace,
  WorkspaceService_ListWorkspaces,
  WorkspaceService_UndeleteWorkspace,
} from './gen/ai/h2o/workspace/v1/workspace_api_pb';

export class AuthzServiceImpl {
  private basePath: string;
  constructor(basePath?: string) {
    this.basePath = basePath || '';
  }

  public setBasePath = (path: string) => {
    this.basePath = path;
  };

  public async getWorkspaces(req: ListWorkspacesRequest) {
    return await fetchWrapRPC(WorkspaceService_ListWorkspaces, { basePath: this.basePath })({ ...req });
  }

  public async createWorkspace(req: CreateWorkspaceRequest) {
    return await fetchWrapRPC(WorkspaceService_CreateWorkspace, { basePath: this.basePath })({ ...req });
  }

  public async deleteWorkspace(req: DeleteWorkspaceRequest) {
    return await fetchWrapRPC(WorkspaceService_DeleteWorkspace, { basePath: this.basePath })({ ...req });
  }

  public async undeleteWorkspace(req: UndeleteWorkspaceRequest) {
    return await fetchWrapRPC(WorkspaceService_UndeleteWorkspace, { basePath: this.basePath })({ ...req });
  }

  public async getUsers(req: ListUsersRequest) {
    return await fetchWrapRPC(UserService_ListUsers, { basePath: this.basePath })({ ...req });
  }

  public async getRoles(req: ListRolesRequest) {
    return await fetchWrapRPC(RoleService_ListRoles, { basePath: this.basePath })({ ...req });
  }

  public async createRoleBinding(req: CreateRoleBindingRequest) {
    return await fetchWrapRPC(RoleBindingService_CreateRoleBinding, { basePath: this.basePath })({ ...req });
  }

  public async getRoleBindings(req: ListRoleBindingsRequest) {
    return await fetchWrapRPC(RoleBindingService_ListRoleBindings, { basePath: this.basePath })({ ...req });
  }

  public async deleteRoleBinding(req: DeleteRoleBindingRequest) {
    return await fetchWrapRPC(RoleBindingService_DeleteRoleBinding, { basePath: this.basePath })({ ...req });
  }
}
