import { mergeStyleSets } from '@fluentui/react';
import { FontSizes, FontWeights, IH2OTheme, buttonStylesStealth, buttonStylesTextWithIconRight } from '@h2oai/ui-kit';

export const signInButtonStyles = (theme: IH2OTheme) =>
  mergeStyleSets(buttonStylesStealth, buttonStylesTextWithIconRight, {
    root: {
      border: 'none',
      fontSize: FontSizes.small,
      color: theme.semanticColors?.textPrimary,
    },
    rootHovered: {
      color: theme.semanticColors?.textPrimary,
    },
    label: {
      fontWeight: FontWeights.fw400,
    },
    icon: {
      color: theme.semanticColors?.textPrimary,
      fontWeight: FontWeights.fw800,
      fontSize: '0.7em',
    },
  });
