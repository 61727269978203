import { IColumn } from '@fluentui/react';

import { Workspace } from '../../authz/gen/ai/h2o/workspace/v1/workspace_pb';
import { RowHeaderTitle } from '../../components/RowHeaderTitle/RowHeaderTitle';

export const workspacesTableColumns: IColumn[] = [
  {
    key: 'title',
    name: 'Title',
    fieldName: 'name',
    minWidth: 420,
    maxWidth: 500,
    data: {
      headerFieldName: 'displayName',
      listCellProps: {
        onRenderHeader: ({ displayName }: Workspace) => RowHeaderTitle({ title: displayName }),
        iconProps: {
          iconName: 'Stack',
        },
      },
    },
  },
  {
    key: 'creator',
    name: 'Owner',
    fieldName: 'creator',
    minWidth: 200,
    maxWidth: 250,
  },
  {
    key: 'sharedWith',
    name: 'Shared with',
    fieldName: '',
    minWidth: 200,
    maxWidth: 200,
    data: {
      listCellProps: {
        emptyMessage: 'Users',
      },
    },
  },
  {
    key: 'description',
    name: 'Description',
    fieldName: 'description',
    minWidth: 200,
    data: {
      listCellProps: {
        emptyMessage: 'No description',
      },
    },
  },
  {
    key: 'actions',
    name: 'Actions',
    fieldName: '',
    minWidth: 200,
    styles: {
      root: {
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'end',
        ':has(.h2o-Item-root)': {
          // TODO: Use theme color.
          backgroundImage: 'linear-gradient(to right, white , var(--h2o-green100, #D0F5D1))',
          borderRadius: 2,
        },
      },
    },
  },
];
