import { FontSizes, defaultPalette } from '@h2oai/ui-kit';

export const adminSettingsViewPanelLabel = {
  styles: {
    root: {
      fontSize: FontSizes.xsmall,
      color: defaultPalette?.gray500,
      width: '40%',
    },
  },
};

export const adminSettingsViewPanelValue = {
  styles: {
    root: {
      fontSize: FontSizes.xsmall,
      color: defaultPalette?.gray900,
      whiteSpace: 'nowrap',
      maxWidth: '60%',
      overflow: 'hidden' as unknown as 'hidden', // not sure why this is needed, but it is
    },
  },
};
