import { type IColumn } from '@fluentui/react';

import { GIBIBYTE } from '../../components/AdminSettings/constants';
import { EntityActionType, EntityFieldType } from '../../components/AdminSettings/Entity/constants';
import { type Entity, type EntityAction, type EntityField } from '../../components/AdminSettings/Entity/types';
import { bytesToGiBWithPostfix, onRenderDisplayName } from '../../components/AdminSettings/Entity/utils';
import { onRenderBooleanCell, onRenderDataCell } from '../../utils/render';
import { type KernelTemplate } from '../gen/ai/h2o/notebook/v1/kernel_template_pb';
import {
  type CreateKernelTemplateRequest,
  type CreateKernelTemplateResponse,
  type DeleteKernelTemplateRequest,
  type DeleteKernelTemplateResponse,
  type GetKernelTemplateRequest,
  type GetKernelTemplateResponse,
  KernelTemplateService_CreateKernelTemplate,
  KernelTemplateService_DeleteKernelTemplate,
  KernelTemplateService_GetKernelTemplate,
  KernelTemplateService_ListKernelTemplates,
  type ListKernelTemplatesRequest,
  type ListKernelTemplatesResponse,
} from '../gen/ai/h2o/notebook/v1/kernel_template_service_pb';
import type { BigIntString } from '../gen/runtime';
import type { KernelTemplateWithId } from '../types';
import { kernelTemplatesResponseMock } from './mocks/responses.mock';
import { NotebookKernelEntityType } from './types';

const entityType = NotebookKernelEntityType.KernelTemplate;
const required = true;

export const actions = {
  [EntityActionType.Create]: {
    name: 'CreateKernelTemplate',
    rpc: KernelTemplateService_CreateKernelTemplate,
    type: EntityActionType.Create,
    mockResponse: kernelTemplatesResponseMock[0],
    responseKey: 'kernelTemplate',
    requestNameKey: 'kernelTemplateId',
    requestPayloadKey: 'kernelTemplate',
    mockPath: '/kernelTemplate',
    entityType,
  } as EntityAction<
    KernelTemplate,
    NotebookKernelEntityType,
    CreateKernelTemplateRequest,
    CreateKernelTemplateResponse
  >,
  [EntityActionType.Get]: {
    name: 'GetKernelTemplate',
    rpc: KernelTemplateService_GetKernelTemplate,
    type: EntityActionType.Get,
    mockResponse: kernelTemplatesResponseMock[0],
    responseKey: 'kernelTemplate',
    mockPath: '/kernelTemplate',
    entityType,
  } as EntityAction<KernelTemplate, NotebookKernelEntityType, GetKernelTemplateRequest, GetKernelTemplateResponse>,
  [EntityActionType.List]: {
    name: 'ListKernelTemplates',
    rpc: KernelTemplateService_ListKernelTemplates,
    type: EntityActionType.List,
    mockResponse: kernelTemplatesResponseMock,
    responseKey: 'kernelTemplates',
    mockPath: '/kernelTemplates',
    entityType,
  } as EntityAction<KernelTemplate, NotebookKernelEntityType, ListKernelTemplatesRequest, ListKernelTemplatesResponse>,
  [EntityActionType.Delete]: {
    name: 'DeleteKernelTemplate',
    rpc: KernelTemplateService_DeleteKernelTemplate,
    type: EntityActionType.Delete,
    mockResponse: kernelTemplatesResponseMock,
    mockPath: '/kernelTemplate',
    entityType,
  } as EntityAction<
    KernelTemplate,
    NotebookKernelEntityType,
    DeleteKernelTemplateRequest,
    DeleteKernelTemplateResponse
  >,
};

export const columns: IColumn[] = [
  {
    key: 'name',
    name: 'Name',
    minWidth: 200,
    maxWidth: 350,
    onRender: onRenderDisplayName,
  },
  {
    key: 'disabled',
    name: 'Disabled',
    fieldName: 'disabled',
    minWidth: 80,
    maxWidth: 120,
    onRender: onRenderBooleanCell,
  },
  {
    key: 'gpu',
    name: 'GPU',
    fieldName: 'gpu',
    minWidth: 80,
    maxWidth: 120,
    onRender: onRenderDataCell,
  },
  {
    key: 'memoryBytesRequest',
    name: 'Memory Request',
    fieldName: 'memoryBytesRequest',
    minWidth: 110,
    maxWidth: 150,
    onRender: (...arg) => onRenderDataCell(...arg, bytesToGiBWithPostfix),
  },
  {
    key: 'memoryBytesLimit',
    name: 'Memory Limit',
    fieldName: 'memoryBytesLimit',
    minWidth: 100,
    maxWidth: 150,
    onRender: (...arg) => onRenderDataCell(...arg, bytesToGiBWithPostfix),
  },
  {
    key: 'storageBytes',
    name: 'Storage',
    fieldName: 'storageBytes',
    minWidth: 100,
    maxWidth: 150,
    onRender: (...arg) => onRenderDataCell(...arg, bytesToGiBWithPostfix),
  },
  {
    key: 'actions',
    name: 'Actions',
    fieldName: 'actions',
    minWidth: 170,
    maxWidth: 170,
  },
];

export const fields: EntityField<KernelTemplateWithId>[] = [
  {
    name: 'name',
    type: EntityFieldType.Hidden,
    label: 'Name',
  },
  {
    name: 'kernelTemplateId',
    referenceName: 'name',
    type: EntityFieldType.NameId,
    label: 'Name',
    description: 'Kernel Template resource name.',
    relayValueTo: ['name'],
    readOnlyOnEdit: true,
    required,
  },
  {
    name: 'milliCpuRequest',
    type: EntityFieldType.Number,
    label: 'MilliCPU Request',
    description: `
      MilliCPU units that will be reserved for the Kernel.
      It is recommended to set CPU requests to avoid resource contention.
      1000 milliCPU = 1 CPU core.
    `,
    minimum: 1,
  },
  {
    name: 'milliCpuLimit',
    type: EntityFieldType.Number,
    label: 'MilliCPU Limit',
    description: `
      Maximum MilliCPU units a kernel is allowed to use.
      The Kernel will be throttled to not exceed this limit.
      It is discouraged to set CPU limits when possible.
      1000 milliCPU = 1 CPU core.
    `,
    minimum: 1,
  },
  {
    name: 'gpuResource',
    type: EntityFieldType.Text,
    label: 'GPU Resource',
    description: `
      Kubernetes GPU resource name. For example: \`nvidia.com/gpu\` or \`amd.com/gpu\`.
      When unset, server will choose a default value.
    `,
  },
  {
    name: 'gpu',
    type: EntityFieldType.Number,
    label: 'GPU',
    minimum: 0,
    // required,
  },
  {
    name: 'memoryBytesRequest',
    type: EntityFieldType.Bytes,
    label: 'Memory Request (GiB)',
    minimum: 1,
    description: 'Memory in bytes that will be reserved for the Kernel.',
  },
  {
    name: 'memoryBytesLimit',
    type: EntityFieldType.Bytes,
    label: 'Memory Limit (GiB)',
    minimum: 1,
    description: `
      Max memory in bytes a kernel is allowed to use.
      Kernel may fail or be terminated if it exceeds this limit.
    `,
    required,
  },
  {
    name: 'storageBytes',
    type: EntityFieldType.Bytes,
    label: 'Storage (GiB)',
    minimum: 1,
    description: `
      External ephemeral storage in bytes that will be mounted to the directory of the Kernel.
      When unset, the node disk will be used.
    `,
  },
  {
    name: 'storageClassName',
    type: EntityFieldType.Text,
    label: 'Storage Class Name',
  },
  {
    name: 'environmentalVariables',
    type: EntityFieldType.KeyValuePair,
    label: 'Environmental Variables',
    description: 'Kubernetes storage class name for the ephemeral storage.',
  },
  {
    name: 'yamlPodTemplateSpec',
    type: EntityFieldType.Yaml,
    label: 'YAML Pod Template Spec',
    description: 'YAML representation of custom PodTemplateSpec serialized into bytes.',
  },
  {
    name: 'disabled',
    type: EntityFieldType.Boolean,
    label: 'Disabled',
  },
  {
    name: 'maxIdleDuration',
    type: EntityFieldType.Duration,
    minimum: 0,
    maximum: 3600,
    label: 'Max Idle Duration',
    required,
    description: 'Maximum time a Kernel can be idle before it is automatically shutdown.',
  },
];

export const kernelTemplateEntity: Entity<KernelTemplateWithId, NotebookKernelEntityType> = {
  type: entityType,
  name: 'kernel-template',
  createButtonLabel: '',
  displayName: 'Kernel Template',
  displayNameKey: 'name',
  requestNameKeyPrefix: 'workspaces/global/kernelTemplates/',
  actions,
  columns,
  fields,
  emptyModel: {
    name: '',
    milliCpuRequest: 1,
    milliCpuLimit: 1,
    gpuResource: '',
    gpu: 0,
    memoryBytesRequest: null as unknown as BigIntString,
    memoryBytesLimit: GIBIBYTE.toString() as BigIntString,
    storageBytes: null as unknown as BigIntString,
    storageClassName: '',
    environmentalVariables: {},
    yamlPodTemplateSpec: '',
    disabled: false,
    maxIdleDuration: '0s',
  },
  canBeReordered: false,
};
