import { IStyle, Stack } from '@fluentui/react';
import { Loader, useClassNames } from '@h2oai/ui-kit';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { stackStylesPage } from '../../themes/themes';
import { ClassNamesFromIStyles } from '../../utils/models';
import { useRoles } from './RoleProvider';

interface INavigationWrapperStyles {
  container: IStyle;
  stack: IStyle;
  noPermissions: IStyle;
}

const navigationWrapperStyles = (): Partial<INavigationWrapperStyles> => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  stack: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
  },
  noPermissions: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    whiteSpace: 'pre-line',
    textAlign: 'center',
  },
});

const NavigationWrapper: React.FC = ({ children }) => {
  const { permissions, loadingPermissions } = useRoles(),
    history = useHistory(),
    classNames = useClassNames<INavigationWrapperStyles, ClassNamesFromIStyles<INavigationWrapperStyles>>(
      'navigationWrapper',
      navigationWrapperStyles
    ),
    timeoutRef = React.useRef<number>(),
    [loading, setLoading] = React.useState(true),
    [showLoader, setShowLoader] = React.useState<boolean>(false),
    [restrictAccess, setRestrictAccess] = React.useState(true);

  React.useEffect(() => {
    if (!loadingPermissions) {
      const pathsPermissions = [
        { path: 'accessControl', permission: permissions.canViewAccessControl },
        { path: 'workflows', permission: permissions.canViewWorkflows },
        { path: 'executorPools', permission: permissions.canViewRunnables },
        { path: 'runnables', permission: permissions.canViewRunnables },
        { path: 'activeExecutions', permission: permissions.canViewWorkflows },
        { path: 'executions', permission: permissions.canViewWorkflows },
      ];

      const restrict = pathsPermissions.some(
        ({ path, permission }) => history.location.pathname?.includes(path) && !permission
      );

      setRestrictAccess(restrict);
      setLoading(false);
    }
  }, [history.location.pathname, loadingPermissions, permissions]);

  React.useEffect(() => {
    if (loading) {
      timeoutRef.current = window.setTimeout(() => setShowLoader(true), 1000);
    } else {
      setShowLoader(false);
      window.clearTimeout(timeoutRef.current);
    }
    return () => window.clearTimeout(timeoutRef.current);
  }, [loading]);

  return (
    <div className={classNames.container}>
      <Stack styles={stackStylesPage} className={classNames.stack}>
        {restrictAccess ? (
          <div className={classNames.noPermissions}>
            {loading ? (
              showLoader ? (
                <Loader label="Just a moment, checking your permissions" />
              ) : null
            ) : (
              `You have no access to this page.\nAsk the workspace owner to grant you permissions.`
            )}
          </div>
        ) : (
          children
        )}
      </Stack>
    </div>
  );
};

export default NavigationWrapper;
