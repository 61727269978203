import { IContextualMenuItem, Icon } from '@fluentui/react';
import { AppName, AppUser, MenuItem, NavigationSectionConfig, convertNavLinksToMenuItems } from '@h2oai/ui-kit';

import { AppStoreMenuName } from '../../discovery-service/constants';
import { ROUTES } from '../../pages/MLOps/constants';
import ProjectNavWidget from '../../pages/MLOps/ProjectNavWidget';
import { ENDPOINTS } from '../../pages/Orchestrator/apiEndpoints';
import { Permissions } from '../../pages/Orchestrator/RoleProvider';
import WorkspaceNavWidget from '../../pages/Orchestrator/WorkspaceNavWidget';
import { RoutePaths } from '../../pages/Routes';
import { CloudPlatformDiscoveryConfig, EnvironmentAndMenu } from '../../utils/contexts';
import type { AppStoreMenuInfo, MapMenuItem, NavigationConfig } from './types';

const navArrayToObject = <T extends { key: string }>(arr: T[] = []): MapMenuItem<T> => {
  return arr.reduce((acc, item) => {
    acc[item.key.replace(/ /g, '')] = item;

    return acc;
  }, {});
};

const getMenuElementsIfExist = (menuObject: MapMenuItem<any>, keys: string[]) => {
  const elements: any[] = [];

  keys.forEach((key: string) => {
    if (menuObject[key]) {
      elements.push(menuObject[key]);
    }
  });

  return elements;
};

const getPermissionBasedOrchestratorRoutes = (permissions?: Permissions) => {
  const routes = [
    {
      key: 'orchestrator-home',
      name: 'Home',
      to: '/orchestrator',
      image: <Icon style={{ fontSize: 18 }} iconName="Home" />,
    },
  ];
  if (!permissions) return routes;
  if (permissions.canViewRunnables) {
    routes.push(
      {
        key: 'orchestrator-executor-pools',
        name: 'Executor pools',
        to: `/orchestrator/redirect${ENDPOINTS.EXECUTOR_POOLS}`,
        image: <Icon style={{ fontSize: 18 }} iconName="Devices2" />,
      },
      {
        key: 'orchestrator-runnables',
        name: 'Runnables',
        to: `/orchestrator/redirect${ENDPOINTS.RUNNABLES}`,
        image: <Icon style={{ fontSize: 18 }} iconName="Rocket" />,
      }
    );
  }
  if (permissions.canViewWorkflows) {
    routes.push(
      {
        key: 'orchestrator-workflows',
        name: 'Workflows',
        to: `/orchestrator/redirect${ENDPOINTS.WORKFLOWS}`,
        image: <Icon style={{ fontSize: 18 }} iconName="Flow" />,
      },
      {
        key: 'orchestrator-executions',
        name: 'Active executions',
        to: `/orchestrator/redirect${ENDPOINTS.ACTIVE_EXECUTIONS}`,
        image: <Icon style={{ fontSize: 18 }} iconName="Play" />,
      }
    );
  }
  return routes;
};

export const getAppStoreMenuInfo = (
  hasAiEngineManager?: boolean,
  user?: AppUser,
  navLinks: { label: string; url: string }[] = [],
  bookTimeLink = 'https://h2o.ai',
  hasHomePage = false,
  federationEnabled = false,
  url?: string,
  hasMenu?: boolean,
  appName: AppName = AppName.appStore,
  environmentName?: string,
  hasPlatformUsage?: boolean,
  isPublicMode = false,
  hasNotebook = false,
  hasSecureStore = false
): AppStoreMenuInfo => {
  const { hasFullAccess, visitorModeEnabled, isAdmin, isSuperAdmin } = user || {};
  const isVisitor = visitorModeEnabled || (!isAdmin && !hasFullAccess);
  const hasBookTime = isVisitor && !!bookTimeLink;
  const externalMenuItems = convertNavLinksToMenuItems(navLinks, appName);
  const hasFeatureStore =
    hasMenu && externalMenuItems && externalMenuItems.some(({ name }) => name === AppStoreMenuName.featureStore);

  return {
    hasAiEngineManager,
    user,
    externalMenuItems,
    bookTimeLink,
    hasBookTime,
    hasHomePage,
    hasMyApp: hasBookTime || (hasFullAccess && !visitorModeEnabled),
    hasFeatureStore,
    hasAdminMarketPlaceApps: isAdmin && !visitorModeEnabled && federationEnabled,
    hasAdminMenus: isAdmin && !visitorModeEnabled,
    hasSuperAdminMenus: isSuperAdmin && !visitorModeEnabled,
    hasVisitorMode: hasFullAccess,
    isPublicMode,
    isVisitorMode: visitorModeEnabled,
    url,
    hasMenu,
    environmentName,
    hasPlatformUsage,
    hasNotebook,
    hasSecureStore,
  };
};

export const getNavigationConfig = (
  userMenu: IContextualMenuItem[] = [],
  env: EnvironmentAndMenu,
  cloudPlatformDiscovery?: CloudPlatformDiscoveryConfig,
  permissions?: Permissions,
  appVersion = '',
  isWorkspacesDisabled = false
): NavigationConfig => {
  const externalMenuItemsObject = navArrayToObject<MenuItem>(env?.menu?.externalMenuItems);
  const userMenuObject = navArrayToObject<IContextualMenuItem>(userMenu);

  const navigationSectionConfig: NavigationSectionConfig = env?.menu?.hasMenu
    ? {
        top: [
          {
            key: 'main-navigation',
            elements: [
              ...(env?.menu?.hasHomePage
                ? [
                    {
                      key: AppStoreMenuName.home,
                      name: AppStoreMenuName.home,
                      dataTest: 'home--main-menu-item',
                      to: RoutePaths.HOME,
                      image: (
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M7.45325 9.61301V16.6609H16.548V9.61301"
                            stroke="#414248"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M6.3158 10.4083L12.0002 6.42969L17.6845 10.4083"
                            stroke="#414248"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M13.7059 16.6609V13.2505C13.7059 12.6229 13.197 12.114 12.5694 12.114H11.4329C10.8064 12.1157 10.2994 12.624 10.2994 13.2505V16.6609"
                            stroke="#414248"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M20.0034 7.99832C20.0034 6.34077 21.3472 4.99707 23.0047 4.99707C21.3472 4.99707 20.0034 3.65336 20.0034 1.99582C20.0034 3.65336 18.6597 4.99707 17.0022 4.99707C18.6597 4.99707 20.0034 6.34077 20.0034 7.99832Z"
                            stroke="#414248"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M21.0038 12V16.0017C21.0038 18.7642 18.7643 21.0037 16.0018 21.0037H7.99842C5.23585 21.0037 2.99634 18.7642 2.99634 16.0017V7.99833C2.99634 5.23576 5.23585 2.99625 7.99842 2.99625H14.0009"
                            stroke="#414248"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      ),
                    },
                  ]
                : []),
              {
                key: AppStoreMenuName.appStore,
                name: AppStoreMenuName.appStore,
                dataTest: 'app-store--main-menu-item',
                to: RoutePaths.APPSTORE,
                image: (
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.21997 10.89V20.89" stroke="#414248" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M19.78 10.89V20.89" stroke="#414248" strokeLinecap="round" strokeLinejoin="round" />
                    <path
                      d="M14.222 20.889V16.445C14.222 15.218 13.227 14.223 12 14.223C10.773 14.223 9.77795 15.218 9.77795 16.445V20.889"
                      stroke="#414248"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path d="M2 20.89H22" stroke="#414248" strokeLinecap="round" strokeLinejoin="round" />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M4.30095 10.889C2.60295 10.877 1.49595 9.19599 2.23195 7.74699L3.94695 4.36999C4.33795 3.59999 5.15995 3.11099 6.06195 3.11099H17.938C18.84 3.11099 19.662 3.59999 20.053 4.36999L21.769 7.74699C22.505 9.19599 21.398 10.877 19.7 10.889C18.283 10.889 17.134 9.92099 17.134 8.72599V8.67799C17.134 9.89899 15.985 10.889 14.568 10.889C13.151 10.889 12.002 9.89899 12.002 8.67799C12.002 9.89899 10.853 10.889 9.43595 10.889C8.01895 10.889 6.86995 9.89899 6.86995 8.67799V8.72599C6.86695 9.92099 5.71795 10.889 4.30095 10.889Z"
                      stroke="#323232"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                ),
              },
            ],
          },
          {
            key: 'my-navigation',
            name: 'My',
            elements: [
              ...(env?.menu?.hasAiEngineManager
                ? [
                    {
                      key: AppStoreMenuName.myAiEngines,
                      dataTest: 'my-ai-engines-menu-item',
                      name: AppStoreMenuName.myAiEngines,
                      to: RoutePaths.MY_AI_ENGINES,
                    },
                  ]
                : []),
              ...(env?.menu?.hasMyApp
                ? [
                    {
                      key: AppStoreMenuName.myApps,
                      dataTest: 'my-apps--main-menu-item',
                      name: AppStoreMenuName.myApps,
                      to: RoutePaths.MYAPPS,
                    },
                  ]
                : []),
              {
                key: AppStoreMenuName.myInstances,
                dataTest: 'my-instances--main-menu-item',
                name: AppStoreMenuName.myInstances,
                to: RoutePaths.INSTANCES,
              },
              ...getMenuElementsIfExist(externalMenuItemsObject, ['MyNotebookLab']).map((menuItem) => {
                return {
                  ...menuItem,
                  name: menuItem.name.replace('My ', ''),
                };
              }),
              ...(env?.menu?.hasBookTime
                ? [
                    {
                      key: AppStoreMenuName.collaborate,
                      name: AppStoreMenuName.collaborate,
                      dataTest: 'book-time--main-menu-item',
                      to: `/book-time`,
                    },
                  ]
                : []),
            ],
          },
          {
            key: 'operations',
            name: 'Operations',
            elements: [
              ...(cloudPlatformDiscovery?.mlopsApiUrl && env?.newMlopsUiEnabled
                ? [
                    {
                      key: 'ml-ops',
                      name: 'MLOps',
                      to: '/mlops',
                      dataTest: 'mlops--main-menu-item',
                      image: <Icon style={{ fontSize: 20 }} iconName="WebAppBuilderFragment" />,
                      section: {
                        top: [
                          {
                            key: 'mlops-sub',
                            elements: [
                              {
                                key: 'mlops-home',
                                name: 'Home',
                                to: '/mlops',
                                image: <Icon style={{ fontSize: 18 }} iconName="Home" />,
                              },
                              {
                                key: 'mlops-models',
                                name: 'Models',
                                to: `/mlops/redirect${ROUTES.MODELS}`,
                                image: <Icon style={{ fontSize: 18 }} iconName="HomeGroup" />,
                              },
                              {
                                key: 'mlops-deployments',
                                name: 'Real-time deployments',
                                to: `/mlops/redirect${ROUTES.DEPLOYMENTS}`,
                                image: <Icon style={{ fontSize: 18 }} iconName="PublishContent" />,
                              },
                              {
                                key: 'mlops-jobs',
                                name: 'Batch scoring jobs',
                                to: `/mlops/redirect${ROUTES.BATCH_SCORING}`,
                                image: <Icon style={{ fontSize: 18 }} iconName="AssessmentGroup" />,
                              },
                            ],
                          },
                        ],
                        bottom: [
                          {
                            key: 'mlops-bottom',
                            elements: [
                              {
                                key: 'mlops-project-settings',
                                image: <ProjectNavWidget />,
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ]
                : []),
              ...(cloudPlatformDiscovery?.orchestratorApiUrl
                ? [
                    {
                      key: 'orchestrator',
                      name: 'Orchestrator',
                      to: RoutePaths.ORCHESTRATOR_WORKSPACE_SELECTION,
                      dataTest: 'orchestrator--main-menu-item',
                      image: <Icon style={{ fontSize: 20 }} iconName="Flow" />,
                      section: {
                        top: [
                          {
                            key: 'orchestrator-sub',
                            elements: getPermissionBasedOrchestratorRoutes(permissions),
                          },
                        ],
                        bottom: [
                          {
                            key: 'orchestrator-bottom',
                            elements: [
                              {
                                key: 'orchestrator-workspace-settings',
                                image: <WorkspaceNavWidget />,
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ]
                : []),
            ],
          },
          {
            key: 'ai-for-documents',
            name: 'AI for Documents',
            elements: [
              ...getMenuElementsIfExist(externalMenuItemsObject, [
                'Enterpriseh2oGPTe',
                'h2oGPT',
                'DocumentAI',
                'EvalStudio',
              ]),
            ],
          },
          {
            key: 'admin',
            name: 'Admin',
            elements: [
              ...(env?.menu?.hasAiEngineManager
                ? [
                    {
                      key: AppStoreMenuName.adminAiEngines,
                      name: AppStoreMenuName.adminAiEngines,
                      dataTest: 'admin-ai-engines--user-menu-item',
                      to: RoutePaths.ADMIN_AI_ENGINES,
                      exact: true,
                    },
                  ]
                : []),
              ...(env?.menu?.hasSuperAdminMenus && env?.menu?.hasAiEngineManager
                ? [
                    {
                      key: AppStoreMenuName.aiEngineSettings,
                      name: AppStoreMenuName.aiEngineSettings,
                      dataTest: 'ai-engine-settings--user-menu-item',
                      to: RoutePaths.AI_ENGINE_SETTINGS,
                    },
                  ]
                : []),
              ...(env?.menu?.hasAdminMarketPlaceApps
                ? [
                    {
                      key: AppStoreMenuName.adminMarketplaceApps,
                      name: AppStoreMenuName.adminMarketplaceApps,
                      dataTest: 'admin-marketplace-apps--user-menu-item',
                      to: `/admin/marketplace/apps`,
                    },
                  ]
                : []),
              ...(env?.menu?.hasAdminMenus
                ? [
                    {
                      key: AppStoreMenuName.adminApps,
                      name: AppStoreMenuName.adminApps,
                      dataTest: 'admin-apps--user-menu-item',
                      to: `/admin/apps`,
                    },
                  ]
                : []),
              ...(env?.menu?.hasAdminMenus
                ? [
                    {
                      key: `${AppStoreMenuName.adminApps}-secrets`,
                      name: 'App Secrets',
                      dataTest: 'admin-apps-secrets--user-menu-item',
                      to: `/admin/secrets`,
                    },
                  ]
                : []),
              ...(env?.menu?.hasAdminMenus
                ? [
                    {
                      key: AppStoreMenuName.adminInstances,
                      name: AppStoreMenuName.adminInstances,
                      dataTest: 'admin-instances--user-menu-item',
                      to: `/admin/instances`,
                    },
                  ]
                : []),
              ...(env?.menu?.hasAdminMenus
                ? [
                    {
                      key: `${AppStoreMenuName.adminInstances}-aliases`,
                      name: 'App Aliases',
                      dataTest: 'admin-instances-aliases--user-menu-item',
                      to: `/admin/aliases`,
                    },
                  ]
                : []),
              ...(env?.menu?.hasNotebook && env?.menu?.hasSuperAdminMenus
                ? [
                    {
                      key: AppStoreMenuName.notebookAdminSettings,
                      name: AppStoreMenuName.notebookAdminSettings,
                      dataTest: 'notebook-admin-settings--user-menu-item',
                      to: `/admin/notebook/settings`,
                    },
                  ]
                : []),
              ...(env?.menu?.hasSecureStore
                ? [
                    {
                      key: AppStoreMenuName.secureStoreOAuthClients,
                      name: AppStoreMenuName.secureStoreOAuthClients,
                      dataTest: 'secure-store-admin-oauth--user-menu-item',
                      to: `/admin/secure-store/oauth-clients`,
                    },
                  ]
                : []),
              ...(!isWorkspacesDisabled
                ? [
                    {
                      key: 'manage-workspaces-nav-element',
                      name: 'Manage Workspaces',
                      dataTest: 'manage-workspaces--user-menu-item',
                      to: RoutePaths.MANAGE_WORKSPACES,
                    },
                  ]
                : []),
            ],
          },
        ],
        bottom: [
          {
            key: 'footer-nav',
            elements: [
              ...(!env?.beamerEnabled
                ? [
                    {
                      key: 'news',
                      name: "What's new",
                      image: <Icon style={{ fontSize: 24 }} iconName="news" />,
                    },
                  ]
                : []),
            ],
          },
          {
            key: 'version',
            elements: [
              {
                key: 'version-title',
                text: 'H2O AI Cloud',
                more: {
                  text: appVersion,
                },
              },
            ],
          },
        ],
      }
    : {};

  const userMenuItems = [
    ...getMenuElementsIfExist(userMenuObject, [
      'Documentation',
      'PlatformUsage',
      'VisitorMode',
      'CLI&APIAccess',
      'About',
      'Logout',
    ]),
  ];

  return {
    navigationSectionConfig,
    userMenuItems,
  };
};
