import { defaultWorkspaceName } from './defaults';
import { BigIntString, toBigIntString } from './gen/runtime';
import { TimeUnit, TimeUnitInfo, timeUnitInfoMap } from './types';

export const getIdFromName = (value: string | null = null) => (!value ? null : value.split('/').reverse()[0]) || '';

// This is the valid regex for it: ^[a-z]([a-z0-9-]{0,61}[a-z0-9])?$
//  source (after make generate): gen/openapiv2/ai/h2o/engine/v1/dai_engine_service.swagger.json:354
export const getRandomNumber = () => Math.floor(Math.random() * 10000);

export const getVariant = (last?: number, candidate = getRandomNumber()): number =>
  !last || last !== candidate ? candidate : getVariant(candidate);

export const extractId = (value: string) =>
  value
    .toLowerCase() // set everything to lowercase
    .replace(/\s+/g, '-') // replace spaces by dashes
    // deletes all non-alphabetical until first alphabetical found character
    // deletes all hyphens and non-alphanumerical characters
    .replace(/^[^a-z]+|[^\w-]|[-]{2,}/g, '')
    .replace(/[-]$/g, ''); // delete last non-alphanumeric character

export const extractWorkspace = (value: string = defaultWorkspaceName) => {
  const name = decodeURI(value).split('/');
  return `${name[0]}/${name[1]}`;
};

export const tryName = (value: string, lastVariant?: number): { id: string; variant: number } => ({
  id: extractId(value),
  variant: lastVariant ? getVariant(lastVariant) : getRandomNumber(),
});

export const concatId = (value: string, variant?: number) => Object.values(tryName(value, variant)).join('-');

export const generateId = (value: string) => concatId(value);

export const GIBIBYTE = 1_073_741_824;

export const gibibytesToBytes = (value: string | number | null | undefined): string | undefined => {
  const number = Number(value);
  if (isNaN(number)) {
    return undefined;
  }
  return (number * GIBIBYTE).toString();
};

export const bytesToGibibytes = (value: string | number | null | undefined): string | undefined => {
  const number = Number(value);
  if (isNaN(number)) {
    return undefined;
  }
  return (number / GIBIBYTE).toFixed(3).toString().replace('.000', '');
};

export const gibibytesToBigIntStringBytes = (value: number): BigIntString | undefined => {
  const bytes = gibibytesToBytes(value);
  if (bytes === undefined) return undefined;
  return toBigIntString(bytes);
};

export const numberGibiBytesToBytes = (gibi: number) => Number(gibibytesToBytes(gibi));
export const milliCPUToCPU = (milliCPU: number | string) => {
  const number = Number(milliCPU) / 1000;
  const formattedNumber = parseFloat(number.toFixed(2)).toString();
  return `${formattedNumber} CPU${number > 1 ? 's' : ''}`;
};

export function formatBytes(size: number): string {
  const units = ['B', 'KiB', 'MiB', 'GiB', 'TiB'];
  let unitIndex = 0;
  while (size >= 1024 && unitIndex < units.length - 1) {
    size /= 1024;
    unitIndex++;
  }
  return `${Math.floor(size)} ${units[unitIndex]}`;
}

export const HOUR_SECONDS = 3_600;
export const DAY_SECONDS = 86_400;
export const WEEK_SECONDS = 604_800;
export const MONTH_SECONDS = 2_592_000;
export const YEAR_SECONDS = 31_536_000;

export const secondsToHours = (value: string | number | null | undefined): string | undefined => {
  const number = typeof value === 'string' ? Number(value?.replace(/[a-zA-Z]/g, '')) : Number(value);
  if (isNaN(number)) {
    return undefined;
  }
  return Math.floor(number / HOUR_SECONDS).toString();
};

export const hoursToSeconds = (value: string | number | null | undefined): string | undefined => {
  const number = Number(value);
  if (isNaN(number)) {
    return undefined;
  }
  return `${(number * HOUR_SECONDS).toString()}s`;
};

type ValueTimeUnitInfoPair = [number, TimeUnitInfo | undefined];

export const parseSeconds = (value: string | number | null | undefined): number =>
  typeof value === 'string' ? Number(value?.replace(/[a-zA-Z]/g, '')) : Number(value);

export const getBestDateTimeUnitInfoPair = (value: string | number | null | undefined): ValueTimeUnitInfoPair => {
  const seconds = parseSeconds(value);
  if (isNaN(seconds)) {
    return [0, undefined];
  }
  switch (true) {
    case seconds < 60:
      return [seconds, timeUnitInfoMap[TimeUnit.Second]];
    case seconds < HOUR_SECONDS:
      return [Math.floor(seconds / 60), timeUnitInfoMap[TimeUnit.Minute]];
    case seconds < DAY_SECONDS:
      return [Math.floor(seconds / HOUR_SECONDS), timeUnitInfoMap[TimeUnit.Hour]];
    case seconds < WEEK_SECONDS:
      return [Math.floor(seconds / DAY_SECONDS), timeUnitInfoMap[TimeUnit.Day]];
    case seconds < MONTH_SECONDS:
      return [Math.floor(seconds / WEEK_SECONDS), timeUnitInfoMap[TimeUnit.Week]];
    case seconds < YEAR_SECONDS:
      return [Math.floor(seconds / MONTH_SECONDS), timeUnitInfoMap[TimeUnit.Month]];
    default:
      return [Math.floor(seconds / YEAR_SECONDS), timeUnitInfoMap[TimeUnit.Year]];
  }
};

export const parseNumberAndTimeUnitToSeconds = (number: number, unit: TimeUnit): string | undefined => {
  let seconds: number;
  if (!number) return undefined;
  switch (unit) {
    case TimeUnit.Minute:
      seconds = number * 60;
      break;
    case TimeUnit.Hour:
      seconds = number * HOUR_SECONDS;
      break;
    case TimeUnit.Day:
      seconds = number * DAY_SECONDS;
      break;
    case TimeUnit.Week:
      seconds = number * WEEK_SECONDS;
      break;
    case TimeUnit.Month:
      seconds = number * MONTH_SECONDS;
      break;
    case TimeUnit.Year:
      seconds = number * YEAR_SECONDS;
      break;
    case TimeUnit.Second:
    default:
      seconds = number;
      break;
  }
  return `${seconds}s`;
};

export const secondsToFriendlyInterval = (value: string | number | null | undefined): string | undefined => {
  const [number, unit] = getBestDateTimeUnitInfoPair(value);
  if (!unit) return value?.toString();
  return `${number} ${number === 1 ? (unit.type === TimeUnit.Day ? 'Tomorrow' : unit.singular) : unit.plural}`;
};
