import { DropdownMenuItemType, IComboBoxOption, IDropdownOption, Stack } from '@fluentui/react';
import {
  Button,
  Dropdown,
  IKeyValuePairEditorClassnames,
  IKeyValuePairEditorStyles,
  buttonStylesGhost,
  buttonStylesPrimary,
  useClassNames,
} from '@h2oai/ui-kit';
import { useCallback, useMemo, useState } from 'react';

import { useRoles } from '../../../Orchestrator/RoleProvider';
import { useUsers } from '../../../Orchestrator/UsersProvider';
import { dropdownStyles, membersListStyles } from './styles';
import { MemberListItemProps } from './types';

export const groupRoles = (roles: IDropdownOption[]): IDropdownOption[] => {
  const groups: { [key: string]: { title: string; items: IDropdownOption[] } } = {
    orchestrator: { title: 'Orchestrator', items: [] },
    drive: { title: 'Drive', items: [] },
    others: { title: 'Others', items: [] },
  };

  roles.forEach((role) => {
    if (String(role.key).includes('roles/orchestrator')) {
      groups.orchestrator.items.push(role);
    } else if (String(role.key).includes('roles/drive')) {
      groups.drive.items.push(role);
    } else {
      groups.others.items.push(role);
    }
  });

  return [
    ...(groups.orchestrator.items.length === 0
      ? []
      : [
          { key: 'orchestrator', text: 'Orchestrator', itemType: DropdownMenuItemType.Header },
          ...groups.orchestrator.items,
        ]),
    ...(groups.drive.items.length === 0
      ? []
      : [{ key: 'drive', text: 'Drive', itemType: DropdownMenuItemType.Header }, ...groups.drive.items]),
    ...(groups.others.items.length === 0
      ? []
      : [{ key: 'others', text: 'Others', itemType: DropdownMenuItemType.Header }, ...groups.others.items]),
  ].filter(Boolean);
};

export const MemberListItem = (props: MemberListItemProps) => {
  const { allRoles } = useRoles();
  const { prepopulatedUser, prepopulatedPermissions = [], usersInList = [] } = props;
  const { users: availableUsers = [], usersNameMap } = useUsers();

  const [selectedUser, setSelectedUser] = useState(prepopulatedUser);
  const [selectedPermissions, setSelectedPermissions] = useState(prepopulatedPermissions);
  const userPermissions = useMemo(() => {
    if (!allRoles) return [];

    const permissionOptions: IDropdownOption[] = allRoles.map((role) => ({
      key: role.name || '',
      text: role.displayName || '',
    }));

    return groupRoles(permissionOptions);
  }, [selectedPermissions, allRoles]);

  const userOptions = useMemo(() => {
    return availableUsers.map(
      (user): IComboBoxOption => ({
        key: String(user.name),
        text: String(user.displayName),
        disabled: usersInList.some((item) => item.name === user.name),
      })
    );
  }, [availableUsers, usersInList]);
  const handlePermissionsChange = useCallback(
    (_e, option) => {
      if (option?.selected) {
        setSelectedPermissions([...selectedPermissions, String(option.key)]);
      } else {
        setSelectedPermissions([...selectedPermissions].filter((permission) => permission !== option?.key));
      }
    },
    [selectedPermissions, setSelectedPermissions]
  );
  const resetForm = useCallback(() => {
    setSelectedUser({ name: '' });
    setSelectedPermissions([]);
  }, [setSelectedUser, setSelectedPermissions]);
  const handleAddUser = useCallback(() => {
    const mappedUser = selectedUser && usersNameMap && usersNameMap[String(selectedUser?.name)];

    if (mappedUser) {
      props.addUser({
        ...usersNameMap[String(selectedUser?.name)],
        name: usersNameMap[String(selectedUser?.name)].name || '',
        permissions: selectedPermissions,
      });
      resetForm();
    }
  }, [selectedUser, usersNameMap, selectedPermissions, props.addUser, resetForm]);
  const classNames = useClassNames<IKeyValuePairEditorStyles, IKeyValuePairEditorClassnames>(
    'MembersList',
    membersListStyles
  );

  return (
    <Stack horizontal horizontalAlign={'space-between'} className={classNames.body}>
      <div className={classNames.keyColumn}>
        {prepopulatedUser ? (
          <b>{prepopulatedUser.displayName}</b>
        ) : (
          <Dropdown
            selectedKey={selectedUser?.name}
            onChange={(_e, selectedUser) => {
              if (usersNameMap) setSelectedUser(usersNameMap[String(selectedUser?.key)]);
            }}
            placeholder="Select User"
            options={userOptions}
            styles={dropdownStyles}
          />
        )}
      </div>
      <div className={classNames.valueColumn}>
        <Dropdown
          multiSelect
          selectedKeys={selectedPermissions}
          onChange={handlePermissionsChange}
          placeholder="Select Permission"
          options={userPermissions}
          disabled={!selectedUser}
        />
      </div>
      <div className={classNames.buttonColumn}>
        {!prepopulatedUser && (
          <Button
            styles={[buttonStylesPrimary]}
            split
            text="Add User"
            onClick={handleAddUser}
            disabled={!selectedUser || !selectedPermissions?.length}
          />
        )}
        {prepopulatedUser && (
          <Button
            styles={[buttonStylesGhost]}
            text="Remove"
            onClick={() => props.removeUser(String(prepopulatedUser?.name))}
          />
        )}
      </div>
    </Stack>
  );
};
