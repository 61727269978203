import { IComponent, useAppNavigationContext, useClassNames } from '@h2oai/ui-kit';
import { ReactNode } from 'react';

import { ILeftPanelStyles, leftPanelStylesDefault } from './LeftPanel.styles';

interface ILeftPanelClassNames {
  root: string;
}
export interface ILeftPanelProps extends IComponent<ILeftPanelStyles> {
  styles?: ILeftPanelStyles;
  content?: ReactNode;
  className?: string;
}

export function LeftPanel({ styles, content }: ILeftPanelProps) {
  const { navigationSize } = useAppNavigationContext();
  const classNames = useClassNames<ILeftPanelStyles, ILeftPanelClassNames>(
    'LeftPanel',
    leftPanelStylesDefault(navigationSize),
    styles
  );
  if (content) {
    return <div className={classNames.root}>{content}</div>;
  }
  return null;
}
