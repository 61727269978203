import { IStyle } from '@fluentui/react';
import { Loader, useClassNames } from '@h2oai/ui-kit';
import React from 'react';
import { Redirect, useParams } from 'react-router-dom';

import { ClassNamesFromIStyles } from '../../utils/models';
import { useProjects } from './ProjectProvider';

interface IProjectRedirectStyles {
  loaderContainer: IStyle;
}

type Props = {
  isMenuItem?: boolean;
};

const projectRedirectStyles: Partial<IProjectRedirectStyles> = {
  loaderContainer: {
    padding: 20,
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    textAlign: 'center',
    justifyContent: 'center',
    minHeight: 450,
    whiteSpace: 'pre-line',
  },
};

const ProjectRedirect: React.FC<Props> = ({ isMenuItem }) => {
  const { ACTIVE_PROJECT_ID, activeProjectInitialized } = useProjects(),
    classNames = useClassNames<IProjectRedirectStyles, ClassNamesFromIStyles<IProjectRedirectStyles>>(
      'projectRedirect',
      projectRedirectStyles
    ),
    timeoutRef = React.useRef<number>(),
    [showLoader, setShowLoader] = React.useState(false),
    params = useParams<{ menu_item: string }>(),
    menuItem = params.menu_item;

  React.useEffect(() => {
    if (!activeProjectInitialized) {
      timeoutRef.current = window.setTimeout(() => setShowLoader(true), 1000);
    } else {
      setShowLoader(false);
      window.clearTimeout(timeoutRef.current);
    }
    return () => window.clearTimeout(timeoutRef.current);
  }, [activeProjectInitialized]);

  return (
    <>
      {!activeProjectInitialized ? (
        <div className={classNames.loaderContainer}>
          {showLoader ? <Loader label="Loading active project info" /> : null}
        </div>
      ) : isMenuItem ? (
        <Redirect to={`/mlops/projects/${ACTIVE_PROJECT_ID}/${menuItem}`} />
      ) : (
        <Redirect to={`/mlops/projects/${ACTIVE_PROJECT_ID}`} />
      )}
    </>
  );
};

export default ProjectRedirect;
