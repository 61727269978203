import { IStyle } from '@fluentui/react';
import { Sizes } from '@h2oai/ui-kit';

export interface ILeftPanelStyles {
  root?: IStyle;
}

export const leftPanelStylesDefault: (offset: number) => Partial<ILeftPanelStyles> = (offset = 0) => ({
  root: {
    width: Sizes.navWidth + Sizes.pagesSidePadding,
    paddingLeft: Sizes.pagesSidePadding,
    paddingTop: Sizes.contentPaddingTop,
    overflow: 'auto',
    transition: 'margin-left .2s, width .2s',
    marginLeft: offset + 4,
  },
});
