import { Stack } from '@fluentui/react';
import { KeyValuePairEditor, Sizes } from '@h2oai/ui-kit';
import type {
  IH2OTheme,
  IKeyValuePairEditorProps,
  IKeyValuePairEditorStyles,
  KeyValuePairValidationFn,
  KeyValueValidation,
} from '@h2oai/ui-kit';
import { useState } from 'react';

import type { EntityFieldInputProps } from './BasicEntityModelComponents/types';
import { defaultEntityFormRowStyles } from './DefaultEntityFormRowStyles';
import { LabelAndDescription } from './LabelAndDescription';

export const KeyValuePairEntityModelField = <EntityModel, EntityType extends string>(
  props: EntityFieldInputProps<EntityModel, EntityType> & { large?: boolean }
) => {
  const { field, model, onChange, large } = props;
  const { name, label, description, referenceName } = field;
  const [config, setConfig] = useState<Record<string, string>>((model as any)[referenceName || name]);

  const validation: KeyValuePairValidationFn = (keyValue: {
    key?: string | undefined;
    value?: string | undefined;
  }): KeyValueValidation => {
    const message: KeyValueValidation = {};
    if (!keyValue.key) {
      message.keyValidation = 'The key may not be empty.';
    }
    if (!keyValue.value) {
      message.valueValidation = 'The value may not be empty.';
    }
    return message;
  };

  const keyValuePairEditorProps: IKeyValuePairEditorProps = {
    config,
    onUpdateConfig: (newConfig: Record<string, string>) => {
      setConfig(newConfig);
      onChange!(referenceName || name, newConfig);
    },
    validation,
  };

  const keyValuePairEditorStyles: (t: IH2OTheme) => Partial<IKeyValuePairEditorStyles> = (theme: IH2OTheme) => {
    return {
      root: {
        maxWidth: `830px`,
      },
      header: {
        border: `solid 1px ${theme.semanticColors?.inputBorder}`,
        borderBottom: 'none',
      },
      body: {
        backgroundColor: theme.semanticColors?.contentBackground,
        border: `solid 1px ${theme.semanticColors?.inputBorder}`,
        borderRadius: `0 0 ${Sizes.borderRadius}px ${Sizes.borderRadius}px`,
      },
    };
  };

  return (
    <Stack
      horizontal={large}
      tokens={{ childrenGap: 15 }}
      styles={
        large ? { root: defaultEntityFormRowStyles } : { root: { maxWidth: '455px', ...defaultEntityFormRowStyles } }
      }
    >
      <LabelAndDescription label={label} light={true} description={description} required={field.required} />
      <KeyValuePairEditor {...keyValuePairEditorProps} styles={keyValuePairEditorStyles} />
    </Stack>
  );
};
