import { FontSizes, IH2OTheme } from '@h2oai/ui-kit';

import { UsersCircleListStyles } from './types';

export const usersCircleListStyle = (theme: IH2OTheme): UsersCircleListStyles => ({
  root: {},
  circle: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '100%',
    border: `1px solid ${theme.palette?.gray300}`,
    backgroundColor: theme.palette?.primary200,
    color: theme.palette?.gray500,
    fontSize: FontSizes.xxxsmall,
    '&:not(:last-child)': { marginRight: -8 },
  },
  usersNumber: {
    marginLeft: 12,
    fontSize: FontSizes.xxsmall,
  },
});
