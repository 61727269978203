import { createContext, useCallback, useContext, useEffect, useState } from 'react';

import { RoleBinding } from '../../authz/gen/ai/h2o/authorization/v1/role_binding_pb';
import { useAuthzService } from '../../authz/hooks';
import { roleBindingsFilterParam } from '../Orchestrator/RoleProvider';
import { useUsers } from '../Orchestrator/UsersProvider';
import { useWorkspaces } from '../Orchestrator/WorkspaceProvider';
import { UserInWorkspace, WorkspaceWithUsers } from './types';

type ManageWorkspaceContextType = {
  workspacesWithUsers?: WorkspaceWithUsers[];
};

const groupBySubject = (roleBindings?: RoleBinding[]): UserInWorkspace[] => {
  if (!roleBindings) return [];

  return Object.values(
    roleBindings.reduce((acc: Record<string, UserInWorkspace>, binding) => {
      const { subject, role, ...rest } = binding;

      if (!subject) return acc;
      if (!acc[subject]) acc[subject] = { subject, role: [], ...rest } as unknown as UserInWorkspace;

      acc[subject].role.push(role!);

      return acc;
    }, {})
  );
};

const ManageWorkspaceContext = createContext<ManageWorkspaceContextType | undefined>(undefined);

export const ManageWorkspacesProvider = ({ children }: { children: React.ReactNode }) => {
  const authzService = useAuthzService();
  const { workspaces } = useWorkspaces();
  const { usersNameMap } = useUsers();
  const [workspacesWithUsers, setWorkspacesWithUsers] = useState<WorkspaceWithUsers[]>();

  const getWorkspacesSharedUsers = useCallback(async () => {
    if (!workspaces) return;

    const withUsers = await Promise.all(
      workspaces.map(async (workspace) => {
        try {
          const roleBindingsResponse = await authzService.getRoleBindings({
            filter: `${roleBindingsFilterParam}${workspace.name}'`,
          });
          const userWithDisplayName = groupBySubject(roleBindingsResponse.roleBindings).map((user) => {
            if (!usersNameMap || !usersNameMap[user.subject]) return user;

            return { ...user, displayName: usersNameMap[user.subject].displayName };
          });

          return { ...workspace, users: userWithDisplayName };
        } catch (error) {
          console.error(`Error fetching role bindings for workspace ${workspace.name}:`, error);
          return { ...workspace, users: [] };
        }
      })
    );

    setWorkspacesWithUsers(withUsers);
  }, [workspaces, usersNameMap]);

  useEffect(() => {
    getWorkspacesSharedUsers();
  }, [workspaces, usersNameMap]);

  const value: ManageWorkspaceContextType = {
    workspacesWithUsers,
  };

  return <ManageWorkspaceContext.Provider value={value}>{children}</ManageWorkspaceContext.Provider>;
};

export const useManageWorkspaces = () => {
  const context = useContext(ManageWorkspaceContext);
  if (!context) {
    throw new Error('useWorkspaces must be used within a WorkspaceProvider');
  }
  return context;
};
