import {
  CreateModelIngestionRequest,
  CreateModelIngestionResponse,
} from '@buf/h2oai_mlops-model-ingestion.bufbuild_es/ai/h2o/mlops/ingest/v1/ingest_service_pb';
import { ModelIngest as ModelIngestService } from '@buf/h2oai_mlops-model-ingestion.connectrpc_es/ai/h2o/mlops/ingest/v1/ingest_service_connect';
import { Artifact } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/artifact_pb';
import {
  CreateArtifactRequest,
  CreateArtifactResponse,
  UpdateArtifactRequest,
} from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/artifact_service_pb';
import { CreateExperimentResponse } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/experiment_service_pb';
import { RegisteredModel } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/registered_model_pb';
import { UpdateRegisteredModelRequest } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/registered_model_service_pb';
import { RegisteredModelVersion } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/registered_model_version_pb';
import {
  CreateModelVersionRequest,
  CreateModelVersionResponse,
} from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/registered_model_version_service_pb';
import { ArtifactService } from '@buf/h2oai_mlops-storage.connectrpc_es/ai/h2o/mlops/storage/v1/artifact_service_connect';
import { ExperimentService } from '@buf/h2oai_mlops-storage.connectrpc_es/ai/h2o/mlops/storage/v1/experiment_service_connect';
import { RegisteredModelService } from '@buf/h2oai_mlops-storage.connectrpc_es/ai/h2o/mlops/storage/v1/registered_model_service_connect';
import { RegisteredModelVersionService } from '@buf/h2oai_mlops-storage.connectrpc_es/ai/h2o/mlops/storage/v1/registered_model_version_service_connect';
import { createPromiseClient } from '@connectrpc/connect';
import { createConnectTransport } from '@connectrpc/connect-web';
import { IStyle, MessageBarType } from '@fluentui/react';
import {
  Button,
  ClassNamesFromIStyles,
  FileUpload,
  IH2OTheme,
  Loader,
  TextField,
  buttonStylesPrimary,
  useClassNames,
  useTheme,
} from '@h2oai/ui-kit';
import { useToast } from '@h2oai/ui-kit';
import React from 'react';
import { useHistory } from 'react-router-dom';

import Header from '../../components/Header/Header';
import { useCloudPlatformDiscovery } from '../../utils/hooks';
import { formatError } from '../../utils/utils';
import { ENDPOINTS } from './constants';
import PageWrapper from './PageWrapper';
import { useProjects } from './ProjectProvider';

export type ModelDetailNavParams = { project_id: string; model_id?: string };

interface IModelDetailStyles {
  form: IStyle;
  loader: IStyle;
}
export const modelDetailStyles = (theme: IH2OTheme): Partial<IModelDetailStyles> => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: 20,
    margin: '0px 40px',
  },
  loader: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 540,
    backgroundColor: theme.semanticColors?.bodyBackground,
  },
});

const ModelDetail = () => {
  const // params = useParams<ModelDetailNavParams>(),
    history = useHistory(),
    theme = useTheme(),
    classNames = useClassNames<IModelDetailStyles, ClassNamesFromIStyles<IModelDetailStyles>>(
      'modelDetail',
      modelDetailStyles(theme)
    ),
    [modelName, setModelName] = React.useState<string>(''),
    [modelDescription, setModelDescription] = React.useState<string>(''),
    [loadingMessage, setLoadingMessage] = React.useState<string>('Adding new model...'),
    { addToast } = useToast(),
    { ACTIVE_PROJECT_ID } = useProjects(),
    [loading, setLoading] = React.useState(false),
    [artifactFile, setArtifactFile] = React.useState<File | null>(null),
    loadStateRef = React.useRef({
      createArtifact: false,
      uploadArtifact: false,
      createModelIngestion: false,
      createExperiment: false,
      updateArtifact: false,
      registerExperimentAsModel: false,
      updateRegisteredModel: false,
    }),
    getLoadingStatusMessage = () => {
      if (loadStateRef.current.createArtifact) return 'Creating artifact...';
      if (loadStateRef.current.uploadArtifact) return 'Uploading artifact...';
      if (loadStateRef.current.createModelIngestion) return 'Ingesting model...';
      if (loadStateRef.current.createExperiment) return 'Creating experiment...';
      if (loadStateRef.current.updateArtifact) return 'Updating artifact...';
      if (loadStateRef.current.registerExperimentAsModel) return 'Registering experiment as model...';
      if (loadStateRef.current.updateRegisteredModel) return 'Updating registered model...';
      return 'Adding new model...';
    },
    evaluateLoading = () => {
      if (
        !loadStateRef.current.createArtifact &&
        !loadStateRef.current.uploadArtifact &&
        !loadStateRef.current.createModelIngestion &&
        !loadStateRef.current.createExperiment &&
        !loadStateRef.current.updateArtifact &&
        !loadStateRef.current.registerExperimentAsModel &&
        !loadStateRef.current.updateRegisteredModel
      ) {
        setLoading(false);
      }
      setLoadingMessage(getLoadingStatusMessage());
    },
    // TODO: Move to context global to mlops.
    cloudPlatformDiscovery = useCloudPlatformDiscovery(),
    mlopsApiUrl = cloudPlatformDiscovery?.mlopsApiUrl || '',
    storageTransport = createConnectTransport({
      baseUrl: `${mlopsApiUrl}${ENDPOINTS.storage}/`,
    }),
    ingestTransport = createConnectTransport({
      baseUrl: `${mlopsApiUrl}${ENDPOINTS.ingest}/`,
    }),
    experimentClient = createPromiseClient(ExperimentService, storageTransport),
    artifactClient = createPromiseClient(ArtifactService, storageTransport),
    registeredModelClient = createPromiseClient(RegisteredModelService, storageTransport),
    registerModelVersionClient = createPromiseClient(RegisteredModelVersionService, storageTransport),
    modelIngestionClient = createPromiseClient(ModelIngestService, ingestTransport),
    createExperiment = React.useCallback(async () => {
      loadStateRef.current.createExperiment = true;
      setLoading(true);
      try {
        const response: CreateExperimentResponse = await experimentClient.createExperiment({
          experiment: {
            displayName: modelName,
            description: modelDescription,
          },
          projectId: ACTIVE_PROJECT_ID,
        });
        addToast({
          messageBarType: MessageBarType.success,
          message: 'Experiment created successfully.',
        });
        return response?.experiment;
      } catch (err) {
        const message = `Failed to create experiment: ${formatError(err)}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
        return undefined;
      } finally {
        loadStateRef.current.createExperiment = false;
        evaluateLoading();
      }
    }, [addToast, ACTIVE_PROJECT_ID, modelName, modelDescription]),
    registerExperimentAsModel = React.useCallback(
      async (experimentId: string) => {
        loadStateRef.current.registerExperimentAsModel = true;
        setLoading(true);
        try {
          /*
          Registering the experiment as a model is usually a two-step process:
          1. Registering the model - RegisteredModelService.createRegisteredModel
          2. Creating a model version - RegisteredModelVersionService.createModelVersion (with the registered model id)
          When omitting "registeredModelId" from second step, a new model will be created automatically (no need to do first step).
          */
          const modelVersionBody = new CreateModelVersionRequest({
            registeredModelVersion: new RegisteredModelVersion({
              experimentId,
              // TODO: Support versioning.
              version: 1,
            }),
            // TODO: Add model name input.
            registeredModelDisplayName: modelName,
            projectId: ACTIVE_PROJECT_ID,
          });
          const response: CreateModelVersionResponse = await registerModelVersionClient.createModelVersion(
            modelVersionBody
          );
          addToast({
            messageBarType: MessageBarType.success,
            message: `Experiment registered as model successfully:, ${response?.registeredModelVersion?.id}`,
          });
          return response?.registeredModelVersion?.registeredModelId;
        } catch (err) {
          const message = `Failed to register experiment as model: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
          return undefined;
        } finally {
          loadStateRef.current.registerExperimentAsModel = false;
          evaluateLoading();
        }
      },
      [addToast, registerModelVersionClient, ACTIVE_PROJECT_ID]
    ),
    createModelIngestion = React.useCallback(
      async (artifactId: string) => {
        loadStateRef.current.createModelIngestion = true;
        setLoading(true);
        try {
          const modelIngestionBody = new CreateModelIngestionRequest({
            artifactId,
          });
          const response: CreateModelIngestionResponse = await modelIngestionClient.createModelIngestion(
            modelIngestionBody
          );
          addToast({
            messageBarType: MessageBarType.success,
            message: 'Model ingested successfully.',
          });
          return response?.ingestion;
        } catch (err) {
          const message = `Failed to ingest model: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
          return undefined;
        } finally {
          loadStateRef.current.createModelIngestion = false;
          evaluateLoading();
        }
      },
      [addToast]
    ),
    createArtifact = React.useCallback(async () => {
      loadStateRef.current.createExperiment = true;
      setLoading(true);
      try {
        const artifact = new Artifact({ entityId: ACTIVE_PROJECT_ID, mimeType: 'application/zip' });
        const artifactRequestBody = new CreateArtifactRequest({
          artifact,
        });
        const response: CreateArtifactResponse = await artifactClient.createArtifact(artifactRequestBody);
        addToast({
          messageBarType: MessageBarType.success,
          message: 'Artifact created successfully.',
        });
        return response?.artifact;
      } catch (err) {
        const message = `Failed to create artifact: ${formatError(err)}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
        return undefined;
      } finally {
        loadStateRef.current.createArtifact = false;
        evaluateLoading();
      }
    }, [addToast, ACTIVE_PROJECT_ID]),
    updateArtifact = React.useCallback(
      async (artifact: Artifact) => {
        loadStateRef.current.updateArtifact = true;
        setLoading(true);
        try {
          const artifactRequestBody = new UpdateArtifactRequest({
            artifact,
            updateMask: {
              paths: ['type,entity_id'],
            },
          });
          await artifactClient.updateArtifact(artifactRequestBody);
          addToast({
            messageBarType: MessageBarType.success,
            message: 'Artifact updated successfully.',
          });
        } catch (err) {
          const message = `Failed to update artifact: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
        } finally {
          loadStateRef.current.updateArtifact = false;
          evaluateLoading();
        }
      },
      [addToast]
    ),
    // Helper to chunk the file into Blobs.
    chunkFile = (file: File, chunkSize: number): Blob[] => {
      const chunks: Blob[] = [];
      let offset = 0;
      while (offset < file.size) {
        const chunk = file.slice(offset, offset + chunkSize);
        chunks.push(chunk);
        offset += chunkSize;
      }
      return chunks;
    },
    encodeBase64 = (uint8Array: Uint8Array): string => {
      let binary = '';
      for (let i = 0; i < uint8Array.byteLength; i++) {
        binary += String.fromCharCode(uint8Array[i]);
      }
      return btoa(binary);
    },
    // Function to stream file to Google ByteStream API.
    streamFileToGoogleByteStream = async (
      file: File,
      resourceName: string,
      // TODO: Reduce chunk size to 256kB and allow streaming multiple chunks with total size greater than 100MB.
      // chunkSize: number = 256 * 1024, // 256 KB per chunk
      chunkSize: number = 1024 * 1024 * 100, // 100MB per chunk
      baseUrl: string
    ): Promise<void> => {
      loadStateRef.current.uploadArtifact = true;
      setLoading(true);
      const chunks = chunkFile(file, chunkSize);
      let writeOffset = 0;

      const messages = [];
      for (let i = 0; i < chunks.length; i++) {
        const chunk = chunks[i];
        const isLastChunk = i === chunks.length - 1;

        // Prepare the data as a protobuf-compatible JSON request.
        messages.push({
          resourceName,
          writeOffset: writeOffset === 0 ? undefined : writeOffset,
          finishWrite: isLastChunk,
          data: encodeBase64(new Uint8Array(await chunk.arrayBuffer())), // Convert Blob to base64 string.
        });

        writeOffset += chunk.size;
      }

      for (const message of messages) {
        const requestBody = JSON.stringify(message);
        const url = new URL(`${baseUrl}/google.bytestream.ByteStream/Write`);

        const response = await fetch(url.toString(), {
          method: 'POST',
          headers: {
            // TODO: Check if headers are correct.
            'Content-Type': 'application/octet-stream',
            'Accept-Encoding': 'gzip, deflate, br',
            'Keep-Alive': 'timeout=5, max=1000',
          },
          body: requestBody,
        });

        if (!response.ok) {
          const errorMessage = await response.text();
          const message = `Error uploading chunk at offset ${writeOffset}: ${errorMessage}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
          break;
        } else {
          if (message.finishWrite) {
            addToast({
              messageBarType: MessageBarType.success,
              message: 'File uploaded successfully.',
            });
          }
        }
      }

      loadStateRef.current.uploadArtifact = false;
      evaluateLoading();
    },
    updateRegisteredModel = React.useCallback(
      async (registeredModel: RegisteredModel) => {
        loadStateRef.current.updateRegisteredModel = true;
        setLoading(true);
        try {
          const updateRegisteredModelBody = new UpdateRegisteredModelRequest({
            registeredModel,
            updateMask: {
              paths: ['description'],
            },
          });
          await registeredModelClient.updateRegisteredModel(updateRegisteredModelBody);
          addToast({
            messageBarType: MessageBarType.success,
            message: 'Model updated successfully.',
          });
          history.goBack();
        } catch (err) {
          const message = `Failed to update model: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
        } finally {
          loadStateRef.current.updateRegisteredModel = false;
          evaluateLoading();
        }
      },
      [addToast]
    ),
    uploadArtifactFile = React.useCallback(
      async (file: File) => {
        const artifact = await createArtifact();
        const artifactId = artifact?.id;
        if (!artifactId) return;

        const baseUrl = `${mlopsApiUrl}/storage`;
        await streamFileToGoogleByteStream(file, artifactId, 256 * 1024, baseUrl);

        addToast({
          messageBarType: MessageBarType.success,
          message: 'Artifact uploaded successfully.',
        });
        const ingestion = await createModelIngestion(artifactId);
        if (!ingestion) return;
        const experiment = await createExperiment();
        if (!experiment) return;
        const registeredModelId = await registerExperimentAsModel(experiment.id);
        if (!registeredModelId) return;
        await updateArtifact(
          new Artifact({
            id: artifactId,
            type: ingestion.artifactType,
            entityId: experiment.id,
          })
        );
        const registeredModel = new RegisteredModel({
          id: registeredModelId,
          description: modelDescription,
          projectId: ACTIVE_PROJECT_ID,
        });
        await updateRegisteredModel(registeredModel);
      },
      [
        addToast,
        createArtifact,
        createModelIngestion,
        createExperiment,
        updateArtifact,
        registerExperimentAsModel,
        updateRegisteredModel,
        ACTIVE_PROJECT_ID,
      ]
    ),
    onUploadModel = React.useCallback(
      (files: File[]) => {
        if (files && files.length) {
          setArtifactFile(files[0]);
        } else {
          setArtifactFile(null);
        }
      },
      [uploadArtifactFile]
    ),
    onClickAddModel = React.useCallback(() => {
      if (artifactFile) uploadArtifactFile(artifactFile);
    }, [artifactFile, uploadArtifactFile]);

  // TODO: Add support for editing existing model.
  // React.useEffect(() => {
  //   if (!defaultWorkflow && params.workflow_id && params.workflow_id !== 'create-new') void getWorkflow();
  // }, [params.workspace_id, params.workflow_id, getWorkflow, defaultWorkflow]);

  return (
    <PageWrapper>
      <Header
        // TODO: Or "Model detail" if editing existing model.
        customPageTitle="Add model"
      />
      <div className={classNames.form}>
        {!loading ? (
          <>
            <TextField
              label="Model name"
              required
              value={modelName}
              onChange={(_ev, newValue) => setModelName(newValue || '')}
            />
            <TextField
              label="Model description"
              value={modelDescription}
              onChange={(_ev, newValue) => setModelDescription(newValue || '')}
            />
            <FileUpload
              fileExtensions={['.zip']}
              label="Model file"
              maxFileSize={100}
              uploadCallback={onUploadModel}
              styles={{ root: { margin: '10px 0' } }}
            />
            <Button
              text="Add model"
              primary
              styles={buttonStylesPrimary}
              disabled={!modelName || !artifactFile}
              onClick={onClickAddModel}
            />
          </>
        ) : (
          <div className={classNames.loader}>
            <Loader label={loadingMessage} />
          </div>
        )}
      </div>
    </PageWrapper>
  );
};
export default ModelDetail;
