import { IStackItemStyles, PanelType, Separator, Stack } from '@fluentui/react';
import { Button, Panel, TextField, buttonStylesPrimary } from '@h2oai/ui-kit';
import React, { useEffect, useState } from 'react';

import { ManageMembers } from '../ManageMembers/ManageMembers';
import { UserWithPermissions } from '../ManageMembers/types';
import { AddEditWorkspaceFormState, AddEditWorkspacePanelProps } from './types';

const stackItemStyles: IStackItemStyles = {
  root: {
    padding: '8px 0',
    flexGrow: 1,
  },
};

const defaultFormState: AddEditWorkspaceFormState = {
  displayName: '',
  description: '',
};

export const AddEditWorkspacePanel = (props: AddEditWorkspacePanelProps) => {
  const { onDismiss, onSave, isOpen } = props;
  const [formState, setFormState] = useState<AddEditWorkspaceFormState>(defaultFormState);
  const [assignedUsers, setAssignedUsers] = useState<UserWithPermissions[]>();
  const [showValidation, setShowValidation] = React.useState(false);
  const handleChange = (field: string) => (_event: any, value?: string) => {
    setFormState((prev) => ({ ...prev, [field]: value || '' }));
  };
  const handleSave = () => {
    if (!formState.displayName) {
      setShowValidation(true);
      return;
    }

    onSave(formState, assignedUsers);
  };

  useEffect(() => {
    if (!isOpen) setFormState(defaultFormState);
  }, [isOpen]);

  return (
    <Panel
      isLightDismiss
      customWidth="700px"
      headerText="Add New Workspace"
      isFooterAtBottom
      isOpen={isOpen}
      onDismiss={onDismiss}
      type={PanelType.custom}
      onRenderFooterContent={() => (
        <Stack horizontal tokens={{ childrenGap: 10 }} horizontalAlign="space-between">
          <Button text="Save" styles={buttonStylesPrimary} onClick={handleSave} role="submit" />
          <Button text="Close" onClick={onDismiss} />
        </Stack>
      )}
    >
      <Stack>
        <Stack horizontal tokens={{ childrenGap: 10 }} horizontalAlign="stretch">
          <Stack styles={stackItemStyles}>
            <TextField
              label="Display Name"
              value={formState.displayName}
              onChange={handleChange('displayName')}
              required
              errorMessage={showValidation && !formState.displayName ? 'Please enter a workspace name' : undefined}
            />
          </Stack>
          <Stack styles={stackItemStyles}>
            <TextField label="Description" value={formState.description} onChange={handleChange('description')} />
          </Stack>
        </Stack>

        <Separator styles={{ root: { margin: '16px 0' } }}>Members and their permissions</Separator>

        <Stack>
          <ManageMembers onMembersListChange={setAssignedUsers} />
        </Stack>
      </Stack>
    </Panel>
  );
};
