import {
  IButtonStyles,
  IContextualMenuItem,
  IContextualMenuListProps,
  IRenderFunction,
  ISearchBoxStyles,
  IStyle,
  Icon,
  KeyCodes,
} from '@fluentui/react';
import {
  Button,
  ClassNamesFromIStyles,
  IH2OTheme,
  IStyleFunctionOrObject,
  Link,
  SearchBox,
  useClassNames,
  useTheme,
} from '@h2oai/ui-kit';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { ROUTES } from './constants';
import { useProjects } from './ProjectProvider';

interface INavigationStyles {
  projectWidget: IStyle;
  widgetTitle: IStyle;
  contextualMenu: IStyle;
  nav: IStyle;
}

const navigationStyles = (theme: IH2OTheme): Partial<INavigationStyles> => ({
    projectWidget: {
      backgroundColor: theme.semanticColors?.bodyBackground,
      padding: '20px 10px',
      borderRadius: 8,
      position: 'absolute',
      bottom: 90,
      maxWidth: 209,
    },
    widgetTitle: {
      color: theme.semanticColors?.messageBarTitleText,
      marginTop: 0,
    },
    contextualMenu: { width: 236 },
    nav: {
      marginTop: 14,
    },
  }),
  filteredItemsStyle: React.CSSProperties = {
    width: '100%',
    height: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  searchBoxStyles: ISearchBoxStyles = {
    root: { margin: '8px' },
  },
  accessControlStyles: IStyleFunctionOrObject<IButtonStyles> | IStyleFunctionOrObject<IButtonStyles>[] | undefined = {
    label: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: 18,
    },
    root: {
      height: 20,
      minHeight: 20,
      padding: '0 8px',
      border: 'none',
    },
  };

const ProjectNavWidget = () => {
  const history = useHistory(),
    theme = useTheme(),
    classNames = useClassNames<INavigationStyles, ClassNamesFromIStyles<INavigationStyles>>(
      'navigationPane',
      navigationStyles(theme)
    ),
    { projects, activateProject, activeProject } = useProjects(),
    menuItems: IContextualMenuItem[] = React.useMemo(
      () =>
        projects?.map((project) => ({
          key: project.id || '',
          text: project.displayName,
          iconProps: project.id === activeProject?.id ? { iconName: 'BoxCheckmarkSolid' } : undefined,
          onClick: () => activateProject(project.id || ''),
        })) || [],
      [activateProject, projects, activeProject]
    ),
    [items, setItems] = React.useState(menuItems),
    onAbort = React.useCallback(() => {
      setItems(menuItems);
    }, [menuItems]),
    onChange = React.useCallback(
      (_ev?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => {
        if (newValue === undefined) return;
        const filteredItems = menuItems.filter(
          (item) => item.text && item.text.toLowerCase().indexOf(newValue.toLowerCase()) !== -1
        );

        if (!filteredItems || !filteredItems.length) {
          filteredItems.push({
            key: 'no_results',
            onRender: (_item, _dismissMenu) => (
              <div key="no_results" style={filteredItemsStyle}>
                <Icon iconName="SearchIssue" title="No projects found" />
                <span>No project found</span>
              </div>
            ),
          });
        }

        setItems(filteredItems);
      },
      [menuItems]
    ),
    onKeyDown = React.useCallback((e) => {
      /* Key Up, but we are not at the beginning of the text: stop event propagation to prevent ContextualMenu to focus */
      if (e.target.selectionStart > 0 && e.which === KeyCodes.up) {
        e.stopPropagation();
      }
      /* Key Down, but we are not at the end of the text: stop event propagation to prevent ContextualMenu to focus */
      if (e.target.selectionStart !== e.target.value.length && e.which === KeyCodes.down) {
        e.stopPropagation();
      }
    }, []),
    onDismiss = React.useCallback(() => {
      setItems(menuItems);
    }, [menuItems]),
    renderMenuList = React.useCallback(
      (menuListProps?: IContextualMenuListProps, defaultRender?: IRenderFunction<IContextualMenuListProps>) => {
        return (
          <div className={classNames.contextualMenu}>
            <div style={{ borderBottom: '1px solid #ccc' }}>
              <SearchBox
                ariaLabel="Filter projects by text"
                placeholder="Filter projects"
                onAbort={onAbort}
                onChange={onChange}
                onKeyDown={onKeyDown}
                styles={searchBoxStyles}
              />
            </div>
            {defaultRender?.(menuListProps)}
            <br />
            <Link
              style={{ marginLeft: 12 }}
              onClick={() => {
                history.push('/mlops/projects');
              }}
            >
              Manage projects
            </Link>
          </div>
        );
      },
      [onAbort, onChange, onKeyDown]
    ),
    menuProps = React.useMemo(
      () => ({
        onRenderMenuList: renderMenuList,
        shouldFocusOnMount: true,
        items,
        focusZoneProps: {
          /* Allow up and down arrows to move focus out of the SearchBox */
          shouldInputLoseFocusOnArrowKey: () => true,
        },
        onDismiss,
      }),
      [items, renderMenuList, onDismiss]
    );

  React.useEffect(() => {
    const newItems: IContextualMenuItem[] =
      projects?.map((project) => ({
        key: project.id || '',
        text: project.displayName,
        iconProps: project.id === activeProject?.id ? { iconName: 'BoxCheckmarkSolid' } : undefined,
        onClick: () => activateProject(project.id || ''),
      })) || [];
    setItems(newItems);
  }, [projects, activeProject, activateProject]);

  return (
    <div className={classNames.projectWidget} id="left-nav-panel-workspace-widget">
      <h4 className={classNames.widgetTitle} id="left-nav-panel-workspace-widget-title">
        Active project
      </h4>
      <Button
        title="Manage projects"
        iconName="FolderHorizontal"
        text={activeProject?.displayName || 'No project selected'}
        menuIconName="ChevronDown"
        menuProps={menuProps}
        styles={{ root: { width: '189px' } }}
      />
      <Button
        title="Project settings"
        text="Project settings"
        onClick={() => {
          history.push(`/mlops/projects/${activeProject?.id}${ROUTES.PROJECT_SETTINGS}`);
        }}
        iconName="Settings"
        styles={accessControlStyles}
        buttonContainerStyles={{ root: { marginTop: 12, textAlign: 'center' } }}
      />
    </div>
  );
};

export default ProjectNavWidget;
