import { IDropdownStyles } from '@fluentui/react';
import { IKeyValuePairEditorStyles } from '@h2oai/ui-kit';

export const dropdownStyles: Partial<IDropdownStyles> = {
  callout: {
    maxHeight: '500px !important',
  },
};

export const membersListStyles: Partial<IKeyValuePairEditorStyles> = {
  keyColumn: {
    width: '30%',
    padding: 8,
    paddingRight: 0,
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
    '> *': { width: '100%' },
  },
  valueColumn: {
    flexGrow: 1,
    padding: 8,
  },
  buttonColumn: {
    width: '16%',
    display: 'flex',
    justifyContent: 'center',
    padding: 8,
    paddingLeft: 0,
    flexShrink: 0,
  },
  body: {
    padding: 0,
  },
};
