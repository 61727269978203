import { Deployment } from '@buf/h2oai_mlops-deployment.bufbuild_es/ai/h2o/mlops/deployer/v1/deployment_pb';
import { ListProjectDeploymentsRequest } from '@buf/h2oai_mlops-deployment.bufbuild_es/ai/h2o/mlops/deployer/v1/deployment_service_pb';
import {
  DeploymentState,
  DeploymentStatus,
} from '@buf/h2oai_mlops-deployment.bufbuild_es/ai/h2o/mlops/deployer/v1/deployment_status_pb';
import { ListDeploymentStatusesRequest } from '@buf/h2oai_mlops-deployment.bufbuild_es/ai/h2o/mlops/deployer/v1/deployment_status_service_pb';
import { ConfigurableEndpoint } from '@buf/h2oai_mlops-deployment.bufbuild_es/ai/h2o/mlops/deployer/v1/endpoint_pb';
import {
  CreateEndpointRequest,
  ListEndpointsRequest,
} from '@buf/h2oai_mlops-deployment.bufbuild_es/ai/h2o/mlops/deployer/v1/endpoint_service_pb';
import { DeploymentService } from '@buf/h2oai_mlops-deployment.connectrpc_es/ai/h2o/mlops/deployer/v1/deployment_service_connect';
import { DeploymentStatusService } from '@buf/h2oai_mlops-deployment.connectrpc_es/ai/h2o/mlops/deployer/v1/deployment_status_service_connect';
import { EndpointService } from '@buf/h2oai_mlops-deployment.connectrpc_es/ai/h2o/mlops/deployer/v1/endpoint_service_connect';
import { DeploymentEnvironment } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/deployment_environment_pb';
import { ListDeploymentEnvironmentsRequest } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/deployment_environment_service_pb';
import { Operator } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/query_pb';
import { DeploymentEnvironmentService } from '@buf/h2oai_mlops-storage.connectrpc_es/ai/h2o/mlops/storage/v1/deployment_environment_service_connect';
import { createPromiseClient } from '@connectrpc/connect';
import { createConnectTransport } from '@connectrpc/connect-web';
import { IStyle, MessageBarType } from '@fluentui/react';
import {
  Dropdown,
  IH2OTheme,
  TextWithCopy,
  dropdownStylesInlineLeft,
  textWithCopyStylesBorder,
  useClassNames,
  useTheme,
  useToast,
} from '@h2oai/ui-kit';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { FailedToLoadView } from '../../components/FailedToLoadView/FailedToLoadView';
import Header from '../../components/Header/Header';
import { NoItemView } from '../../components/NoItemView/NoItemView';
import { RowHeaderTitle } from '../../components/RowHeaderTitle/RowHeaderTitle';
import WidgetList from '../../components/WidgetList/WidgetList';
import { useCloudPlatformDiscovery } from '../../utils/hooks';
import { ClassNamesFromIStyles } from '../../utils/models';
import { formatError } from '../../utils/utils';
import { ContextMenuIconButton } from '../Orchestrator/Workflows';
import { Tag } from '../Orchestrator/WorkflowTabExecutions';
import { ENDPOINTS, ROUTES } from './constants';
import PageWrapper from './PageWrapper';
import { useProjects } from './ProjectProvider';

interface IDeploymentsStyles {
  title: IStyle;
}

type Endpoint = { displayName: string; description: string; path: string };

type DeploymentItem = Deployment & {
  createdTimeLocal: string;
  createdByName: string;
  status: DeploymentStatus;
  onCreateEndpoint?: () => void;
  endpoints?: Endpoint[];
};

const deploymentsStyles = (theme: IH2OTheme): IDeploymentsStyles => {
  return {
    title: {
      marginTop: 0,
      // TODO: Remove once theme is used somewhere else.
      color: theme.semanticColors?.textPrimary,
    },
  };
};

// TODO: Find out how to theme this.
const getStateProps = (state?: DeploymentState) => {
  switch (state) {
    case DeploymentState.LAUNCHING:
      return { name: 'Launching', color: 'var(--h2o-yellow500)' };
    case DeploymentState.FAILED:
      return { name: 'Failed', color: 'var(--h2o-red500)' };
    case DeploymentState.HEALTHY:
      return { name: 'Healthy', color: 'var(--h2o-green500)' };
    case DeploymentState.UNHEALTY:
      return { name: 'Unhealthy', color: 'var(--h2o-red500)' };
    case DeploymentState.TERMINATING:
      return { name: 'Terminating', color: 'var(--h2o-yellow500)' };
    case DeploymentState.PENDING:
      return { name: 'Pending', color: 'var(--h2o-yellow500)' };
    default:
      return { name: 'Unknown', color: 'var(--h2o-gray900)' };
  }
};

const columns = [
  {
    key: 'title',
    name: 'Title',
    fieldName: 'description',
    minWidth: 200,
    maxWidth: 800,
    data: {
      headerFieldName: 'displayName',
      listCellProps: {
        onRenderHeader: ({ displayName }: Deployment) => RowHeaderTitle({ title: displayName }),
        iconProps: {
          iconName: 'FolderHorizontal',
        },
      },
    },
  },
  {
    key: 'endpoints',
    name: 'Endpoints',
    minWidth: 300,
    maxWidth: 800,
    data: {
      listCellProps: {
        emptyMessage: 'No Description',
        onRenderText: ({ status, endpoints }: DeploymentItem) => (
          // TODO: Use theme prop for colors.
          <TextWithCopy
            hasBorder
            header={
              <Dropdown
                options={[
                  ...((endpoints || []).map((endpoint) => ({
                    data: { icon: 'Link' },
                    key: endpoint.path,
                    text: endpoint.displayName,
                  })) || []),
                  {
                    data: { icon: 'AddLink' },
                    key: status.scorer?.score?.url || 'default-key',
                    text: 'Scorer (default)',
                    selected: true,
                  },
                ]}
                placeholder="Endpoints"
                styles={dropdownStylesInlineLeft}
              />
            }
            styles={[
              textWithCopyStylesBorder,
              {
                header: {
                  border: 0,
                  borderRadius: 'none',
                  padding: 0,
                },
              },
            ]}
            // TODO: Update copyable text on dropdown selection.
            text={status.scorer?.score?.url || 'No endpoint'}
          />
        ),
        styles: {
          root: {
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'end',
          },
        },
      },
    },
  },
  {
    key: 'user',
    name: 'Created by',
    fieldName: 'createdByName',
    minWidth: 150,
    maxWidth: 220,
  },
  {
    key: 'createdAt',
    name: 'Created at',
    fieldName: 'createdTimeLocal',
    minWidth: 150,
    maxWidth: 250,
  },
  {
    key: 'status',
    name: '',
    fieldName: 'status',
    minWidth: 150,
    maxWidth: 180,
    data: {
      listCellProps: {
        styles: {
          root: {
            justifyContent: 'center',
          },
        },
        onRenderText: ({ status }: DeploymentItem) => (
          <Tag title={getStateProps(status?.state).name} color={getStateProps(status?.state).color} />
        ),
      },
    },
  },
  {
    key: 'buttons',
    name: '',
    minWidth: 200,
    data: {
      listCellProps: {
        emptyMessage: 'No Description',
        onRenderText: ({ onCreateEndpoint }: DeploymentItem) => (
          // TODO: Use theme prop for colors.
          <ContextMenuIconButton
            items={[
              {
                key: 'createEndpoint',
                text: 'Create endpoint URL',
                onClick: onCreateEndpoint,
                iconProps: {
                  iconName: 'AddLink',
                },
              },
            ]}
          />
        ),
        styles: {
          root: {
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'end',
          },
        },
      },
    },
  },
];

const Deployments = () => {
  const theme = useTheme(),
    classNames = useClassNames<IDeploymentsStyles, ClassNamesFromIStyles<IDeploymentsStyles>>(
      'deployments',
      deploymentsStyles(theme)
    ),
    history = useHistory(),
    { addToast } = useToast(),
    { ACTIVE_PROJECT_ID } = useProjects(),
    loadStateRef = React.useRef({
      fetchDeployments: false,
      fetchDeploymentStatuses: false,
      creatingEndpoint: false,
      fetchDeploymentEnvironments: false,
      fetchEndpoints: false,
    }),
    [loading, setLoading] = React.useState(true),
    [isLoadingMore, setIsLoadingMore] = React.useState(false),
    [isLoadingSearch, setIsLoadingSearch] = React.useState(false),
    [nextPageToken, setNextPageToken] = React.useState<string>(),
    [deploymentItems, setDeploymentItems] = React.useState<DeploymentItem[]>(),
    [deploymentStatusItems, setDeploymentStatusItems] = React.useState<DeploymentStatus[]>(),
    [deploymentEnvironments, setDeploymentEnvironments] = React.useState<DeploymentEnvironment[]>(),
    [endpoints, setEndpoints] = React.useState<ConfigurableEndpoint[]>(),
    // TODO: Move to context global to mlops.
    cloudPlatformDiscovery = useCloudPlatformDiscovery(),
    mlopsApiUrl = cloudPlatformDiscovery?.mlopsApiUrl || '',
    storageTransport = createConnectTransport({
      baseUrl: `${mlopsApiUrl}${ENDPOINTS.storage}/`,
    }),
    deploymentTransport = createConnectTransport({
      baseUrl: `${mlopsApiUrl}${ENDPOINTS.deployment}/`,
    }),
    deploymentClient = createPromiseClient(DeploymentService, deploymentTransport),
    deploymentStatusClient = createPromiseClient(DeploymentStatusService, deploymentTransport),
    endpointClient = createPromiseClient(EndpointService, deploymentTransport),
    deploymentEnvironmentClient = createPromiseClient(DeploymentEnvironmentService, storageTransport),
    evaluateLoading = () => {
      if (
        !loadStateRef.current.fetchDeployments &&
        !loadStateRef.current.fetchDeploymentStatuses &&
        !loadStateRef.current.creatingEndpoint &&
        !loadStateRef.current.fetchDeploymentEnvironments &&
        !loadStateRef.current.fetchEndpoints
      ) {
        setLoading(false);
      }
    },
    onAction = () => history.push(`/mlops/projects/${ACTIVE_PROJECT_ID}${ROUTES.DEPLOYMENTS}/create-new`),
    getEndpoints = React.useCallback(async () => {
      if (!ACTIVE_PROJECT_ID) return;
      loadStateRef.current.fetchEndpoints = true;
      setLoading(true);
      try {
        const parentEnv = `projects/${ACTIVE_PROJECT_ID}/environments/${
          deploymentEnvironments?.[0].id || '244f6087-5cb2-4fe6-94bd-37916474e404'
        }`;
        const listEndpointsBody = new ListEndpointsRequest({
          parent: parentEnv,
        });
        const response = await endpointClient.listEndpoints(listEndpointsBody);
        const endpoints: ConfigurableEndpoint[] | undefined = response?.endpoints;
        setEndpoints(endpoints);
        if (response && !endpoints) console.error('No endpoints found in the response.');
      } catch (err) {
        const message = `Failed to fetch endpoints: ${formatError(err)}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
        setEndpoints(undefined);
      } finally {
        loadStateRef.current.fetchEndpoints = false;
        evaluateLoading();
      }
    }, [ACTIVE_PROJECT_ID, addToast, deploymentEnvironments]),
    // TODO: Move to global scope.
    getDeploymentEnvironments = React.useCallback(async () => {
      if (!ACTIVE_PROJECT_ID) return;
      loadStateRef.current.fetchDeploymentEnvironments = true;
      setLoading(true);
      try {
        const listDeploymentEnvironmentsRequest = new ListDeploymentEnvironmentsRequest({
          projectId: ACTIVE_PROJECT_ID,
        });
        const response = await deploymentEnvironmentClient.listDeploymentEnvironments(
          listDeploymentEnvironmentsRequest
        );
        const deploymentEnvironments: DeploymentEnvironment[] | undefined = response?.deploymentEnvironment;
        setDeploymentEnvironments(deploymentEnvironments);
        if (response && !deploymentEnvironments) console.error('No deployment environments found in the response.');
      } catch (err) {
        const message = `Failed to fetch deployment environments: ${formatError(err)}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
        setDeploymentEnvironments(undefined);
      } finally {
        loadStateRef.current.fetchDeploymentEnvironments = false;
        evaluateLoading();
      }
    }, [ACTIVE_PROJECT_ID, addToast]),
    createCustomEndpoint = React.useCallback(
      async (deploymentId: string, path: string) => {
        if (!ACTIVE_PROJECT_ID) return;
        loadStateRef.current.creatingEndpoint = true;
        try {
          const parentEnv = `projects/${ACTIVE_PROJECT_ID}/environments/${
            deploymentEnvironments?.[0].id || '244f6087-5cb2-4fe6-94bd-37916474e404'
          }`;
          const createEndpointBody = new CreateEndpointRequest({
            parent: parentEnv,
            endpoint: new ConfigurableEndpoint({
              displayName: 'New endpoint',
              description: 'New endpoint description',
              path,
              target: `${parentEnv}/deployments/${deploymentId}`,
            }),
          });
          await endpointClient.createEndpoint(createEndpointBody);
          addToast({
            messageBarType: MessageBarType.success,
            message: 'Endpoint created successfully.',
          });
        } catch (err) {
          const message = `Failed to create endpoint: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
        } finally {
          loadStateRef.current.creatingEndpoint = false;
          evaluateLoading();
        }
      },
      [addToast, ACTIVE_PROJECT_ID, deploymentEnvironments]
    ),
    onCreateEndpointAction = React.useCallback(
      (deploymentId: string) => {
        // TODO: Open dialog with input for custom path.
        createCustomEndpoint(deploymentId, '');
      },
      [createCustomEndpoint]
    ),
    fetchDeployments = React.useCallback(
      async (pageToken?: string, filter?: string) => {
        if (!ACTIVE_PROJECT_ID) return;
        loadStateRef.current.fetchDeployments = true;
        if (pageToken) setIsLoadingMore(true);
        else if (filter || filter === `""`) setIsLoadingSearch(true);
        else setLoading(true);
        try {
          const listDeploymentsBody = new ListProjectDeploymentsRequest(
            filter
              ? {
                  projectId: ACTIVE_PROJECT_ID,
                  paging: {
                    pageSize: 20,
                    pageToken: pageToken ? new TextEncoder().encode(pageToken) : undefined,
                  },
                  filter: {
                    query: {
                      clause: [
                        {
                          propertyConstraint: [
                            {
                              property: {
                                propertyType: {
                                  // TODO: Make filter case insensitive.
                                  value: 'name',
                                  case: 'field',
                                },
                              },
                              operator: Operator.CONTAINS,
                              value: {
                                value: {
                                  case: 'stringValue',
                                  value: filter,
                                },
                              },
                            },
                          ],
                        },
                      ],
                    },
                  },
                }
              : {
                  projectId: ACTIVE_PROJECT_ID,
                  paging: {
                    pageSize: 20,
                    pageToken: pageToken ? new TextEncoder().encode(pageToken) : undefined,
                  },
                }
          );
          const response = await deploymentClient.listProjectDeployments(listDeploymentsBody);
          const deploymentItems: Deployment[] | undefined = response?.deployment;
          if (response && !deploymentItems) console.error('No deployments found in the response.');
          setNextPageToken(
            response?.paging?.nextPageToken ? new TextDecoder().decode(response.paging.nextPageToken) : undefined
          );
          const parentEnv = `projects/${ACTIVE_PROJECT_ID}/environments/${
            deploymentEnvironments?.[0].id || '244f6087-5cb2-4fe6-94bd-37916474e404'
          }`;
          const newItems: DeploymentItem[] | undefined = deploymentItems?.map(
            (item) =>
              ({
                ...item,
                createdTimeLocal:
                  item.createdTime?.seconds !== undefined
                    ? new Date(Number(item.createdTime.seconds) * 1000).toLocaleString()
                    : '',
                // TODO: Display user name instead of the id.
                createdByName: item.userInfo?.ownerName || item.userInfo?.ownerId || '',
                onCreateEndpoint: () => onCreateEndpointAction(item.id),
                status:
                  deploymentStatusItems?.find((status) => status.deploymentId === item.id) || ({} as DeploymentStatus),
                endpoints: endpoints
                  ?.filter((endpoint) => endpoint.target.includes(`${parentEnv}/deployments/${item.id}`))
                  .map((endpoint) => ({
                    displayName: endpoint.displayName,
                    description: endpoint.description,
                    path: endpoint.path,
                  })),
              } as DeploymentItem)
          );
          setDeploymentItems((items) => (pageToken ? [...(items || []), ...(newItems || [])] : newItems));
        } catch (err) {
          const message = `Failed to fetch deployments: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
          setDeploymentItems(undefined);
        } finally {
          loadStateRef.current.fetchDeployments = false;
          evaluateLoading();
          setIsLoadingMore(false);
          setIsLoadingSearch(false);
        }
      },
      [addToast, ACTIVE_PROJECT_ID, onCreateEndpointAction, deploymentEnvironments, endpoints, deploymentStatusItems]
    ),
    fetchDeploymentStatuses = React.useCallback(async () => {
      if (!ACTIVE_PROJECT_ID) return;
      loadStateRef.current.fetchDeploymentStatuses = true;
      try {
        const listDeploymentStatusesBody = new ListDeploymentStatusesRequest({
          projectId: ACTIVE_PROJECT_ID,
        });
        const response = await deploymentStatusClient.listDeploymentStatuses(listDeploymentStatusesBody);
        const deploymentStatusItems: DeploymentStatus[] | undefined = response?.deploymentStatus;
        if (response && !deploymentStatusItems) console.error('No deployment statuses found in the response.');
        setDeploymentStatusItems(deploymentStatusItems);
      } catch (err) {
        const message = `Failed to fetch deployment statuses: ${formatError(err)}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
        setDeploymentStatusItems(undefined);
      } finally {
        loadStateRef.current.fetchDeploymentStatuses = false;
        evaluateLoading();
      }
    }, [addToast, ACTIVE_PROJECT_ID]),
    widgetListProps = {
      columns,
      items: deploymentItems,
      loading: !!loading,
      delayLoader: false,
      isLoadingMore: isLoadingMore,
      onLoadMore: nextPageToken ? () => void fetchDeployments(nextPageToken) : undefined,
      isLoadingSearch,
      searchProps: {
        placeholder: 'Search deployments',
        // TODO: Check if "value" is correct.
        onSearchChange: (value: string) => void fetchDeployments(undefined, value),
      },
      NoItemsContent: NoItemView({
        title: 'No deployments',
        description: 'There are no deployments available in this project. Deploy your models now.',
        actionTitle: 'Create deployment',
        onActionClick: onAction,
        actionIcon: 'Add',
      }),
      ErrorContent: FailedToLoadView({
        title: 'Failed to load deployments',
        description: 'Please try again later. If the problem persists, contact our support.',
        actionTitle: 'Retry',
        onActionClick: fetchDeployments,
        actionIcon: 'Refresh',
      }),
    };

  React.useEffect(() => void fetchDeploymentStatuses(), [fetchDeploymentStatuses]);

  React.useEffect(() => void fetchDeployments(), [fetchDeployments]);

  React.useEffect(() => void getDeploymentEnvironments(), [getDeploymentEnvironments]);

  React.useEffect(() => void getEndpoints(), [getEndpoints]);

  React.useEffect(() => {
    if (deploymentItems && deploymentStatusItems) {
      const newItems: DeploymentItem[] = deploymentItems.map(
        (item) =>
          ({
            ...item,
            status: deploymentStatusItems.find((status) => status.deploymentId === item.id) || ({} as DeploymentStatus),
          } as DeploymentItem)
      );
      setDeploymentItems(newItems);
    }
  }, [deploymentStatusItems]);

  return (
    <PageWrapper>
      {deploymentItems?.length ? <Header action="Create deployment" onActionClick={onAction} actionIcon="Add" /> : null}
      <WidgetList {...widgetListProps} />
      {/* TODO: Remove once classNames is used somewhere. */}
      <div className={classNames.title} style={{ display: 'none' }} />
    </PageWrapper>
  );
};

export default Deployments;
