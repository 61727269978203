import { Stack } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { Loader, LoaderType, TextField } from '@h2oai/ui-kit';
import { useEffect, useReducer, useRef, useState } from 'react';

import { useDebouncedCallback, usePromiseCallback } from '../../../../utils/hooks';
import { validateId } from '../../../../utils/utils';
import { useEntity } from '../../Entity/hooks';
import { FormRow } from '../BasicEntityModelComponents/BasicEntityModelComponents';
import type { EntityFieldInputProps } from '../BasicEntityModelComponents/types';
import type { SemverValidationActions, SemverValidationReducerFunction, SemverValidationState } from './types';

export enum SemverValidationAction {
  AVAILABLE = 'available',
  VALID = 'valid',
}

const onRenderDescription = () => {
  return (
    <Stack styles={{ root: { height: 10, width: 20, paddingTop: 4 } }}>
      <Loader type={LoaderType.progressIndicator} />
    </Stack>
  );
};

const validationReducer: SemverValidationReducerFunction = (
  state: SemverValidationState,
  action: SemverValidationActions
): SemverValidationState => {
  const newState = { ...state };
  newState[action.type] = action.value;
  return newState;
};

export function NameIdEntityModelField<EntityModel, EntityType extends string>(
  props: EntityFieldInputProps<EntityModel, EntityType>
) {
  const { field, model, onChangeMultiple, entityType, isCreate = true, validate } = props;
  const { checkId } = useEntity<EntityType>({
    entitiesMap: props.entitiesMap,
    requestPath: props.requestPath,
  });
  const { name, label, readOnlyOnEdit, relayValueTo, referenceName } = field;
  const [semverValidationState, semverValidationDispatch] = useReducer<SemverValidationReducerFunction>(
    validationReducer,
    {
      available: !isCreate,
      valid: !isCreate,
    }
  );
  const [fieldValue, setFieldValue] = useState<string>((model as any)[referenceName || name] as string);
  const checkErrorMessage = useRef<string>('');
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [successMessage, setSuccessMessage] = useState<string | undefined>();
  const [checking, { setFalse: doneChecking, setTrue: startChecking }] = useBoolean(false);
  const checkIdController = useRef<AbortController>();

  const [useChecker] = usePromiseCallback(
    (value: string) => {
      const payload = value.replaceAll(' ', '-').replace(/\s+$/, '').toLowerCase();
      if (checking && checkIdController.current) {
        checkIdController.current?.abort();
      }
      checkIdController.current = new AbortController();
      // TODO: pass another prop to replace this one "workspaces/global/kernelTemplates/"
      return checkId(entityType, `workspaces/global/kernelTemplates/${payload}`, checkIdController.current);
    },
    [],
    {
      onError: (workerError) => {
        semverValidationDispatch({ type: SemverValidationAction.AVAILABLE, value: false });
        checkErrorMessage.current = workerError.message;
      },
      onSuccess: (isAvailable) => {
        semverValidationDispatch({ type: SemverValidationAction.AVAILABLE, value: isAvailable });
        checkErrorMessage.current = '';
      },
      onSettled: doneChecking,
    }
  );

  const debounceSemverCallback = useDebouncedCallback(useChecker, 300);
  const onChangeSemver = async (_ev: any, value = '') => {
    const isValid = validateId(value);

    semverValidationDispatch({ type: SemverValidationAction.VALID, value: isValid });

    if (isValid) {
      startChecking();
      debounceSemverCallback(value);
    }

    setFieldValue(value || '');

    if (onChangeMultiple) {
      onChangeMultiple({
        [referenceName || name]: value,
        ...(relayValueTo?.length
          ? relayValueTo.reduce((acc, curr) => ({ ...acc, [curr]: value }), {} as Partial<EntityModel>)
          : {}),
      } as Partial<EntityModel>);
    }
  };

  useEffect(() => {
    setSuccessMessage('');
    setErrorMessage('');
    // versions can't be edited:
    if (!isCreate) {
      return;
    }
    if (!fieldValue) {
      validate && validate(false);
      return;
    }
    const { available, valid } = semverValidationState;
    // states and their messages:
    // available: true, valid: true: 'Valid and available.'
    // available: false, valid: true: 'Name already exists.'
    // available: false, valid: false: 'Invalid name.'
    // available: true, valid: false: 'Name must include lowercase letters and the hyphen ("-") character only.'
    if (valid && available) {
      setSuccessMessage('Valid and available');
      validate && validate(true);
      return;
    }
    if (!valid) {
      setErrorMessage('Name must include lowercase letters and the hyphen ("-") character only.');
      validate && validate(false);
      return;
    }
    if (!available) {
      setErrorMessage('Name already exists');
      validate && validate(false);
      return;
    }
    // for when unmounting:
    return () => {
      if (checkIdController && checking) checkIdController.current?.abort();
    };
  }, [semverValidationState]);

  return (
    <FormRow>
      <TextField
        required={field.required}
        readOnly={!isCreate && readOnlyOnEdit}
        label={label}
        value={fieldValue}
        successMessage={successMessage}
        errorMessage={errorMessage}
        onRenderDescription={checking ? onRenderDescription : undefined}
        onChange={onChangeSemver}
      />
    </FormRow>
  );
}
