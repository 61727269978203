import { PanelType, Stack, Text } from '@fluentui/react';
import { Accordion, Button, FontSizes, Panel, useTheme } from '@h2oai/ui-kit';
import { useRef } from 'react';

import { AIEngine } from '../../../../aiem/engine/types';
import { Engine_Type } from '../../../../aiem/gen/ai/h2o/engine/v1/engine_pb';
import { bytesToGibibytes, getIdFromName, secondsToHours } from '../../../../aiem/utils';
import { AdvancedConfiguration } from '../AdvancedConfiguration/AdvancedConfiguration';
import { Badges, EngineLowStorageBadge, EngineResizingBadge } from '../Badges';

export function AIEMPanelView(props: { engine: AIEngine; onDismiss: () => void }) {
  const { engine } = props;
  const { palette } = useTheme();
  const advancedConfigurationRef = useRef<any>();
  const sectionTitle = { styles: { root: { fontSize: FontSizes.small } } };
  const viewLabel = { styles: { root: { fontSize: FontSizes.xsmall, color: palette?.gray500 } } };
  const viewValue = { styles: { root: { fontSize: FontSizes.xsmall, color: palette?.gray900 } } };

  return (
    <Panel
      isLightDismiss
      closeButtonAriaLabel="Close"
      customWidth="800px"
      headerText={engine.displayName || ``}
      isFooterAtBottom={true}
      isOpen={true}
      onDismiss={props.onDismiss}
      onRenderFooterContent={() => <Button text="Close" onClick={props.onDismiss} />}
      type={PanelType.custom}
    >
      <Stack tokens={{ childrenGap: 20 }}>
        <Stack horizontal tokens={{ childrenGap: 4 }}>
          <Badges state={props.engine.state} />
          <EngineLowStorageBadge engine={props.engine} />
          <EngineResizingBadge engine={props.engine} />
        </Stack>
        <Text {...sectionTitle}>Engine Details</Text>
        <Stack horizontal horizontalAlign="space-between">
          <Text {...viewLabel}>Instance Name</Text>
          <Text {...viewValue}>{engine.displayName}</Text>
        </Stack>
        <Stack horizontal horizontalAlign="space-between">
          <Text {...viewLabel}>Engine ID</Text>
          <Text {...viewValue}>{getIdFromName(engine.name)}</Text>
        </Stack>
        <Stack horizontal horizontalAlign="space-between">
          <Text {...viewLabel}>AI Engine</Text>
          <Badges type={engine.engineType} />
        </Stack>
        {engine?.creatorDisplayName && (
          <Stack horizontal horizontalAlign="space-between">
            <Text {...viewLabel}>Owner</Text>
            <Text {...viewValue}>{engine.creatorDisplayName}</Text>
          </Stack>
        )}
        <Stack horizontal horizontalAlign="space-between">
          <Text {...viewLabel}>Version</Text>
          <Text {...viewValue}>{engine.version}</Text>
        </Stack>

        {engine.engineType === Engine_Type.H2O && (
          <Stack horizontal horizontalAlign="space-between">
            <Text {...viewLabel}>Number of Nodes</Text>
            <Text {...viewValue}>{engine.nodeCount}</Text>
          </Stack>
        )}
        <Stack horizontal horizontalAlign="space-between">
          <Text {...viewLabel}>{engine.engineType === Engine_Type.H2O ? 'CPUs per Node' : 'CPU Units'}</Text>
          <Text {...viewValue}>{engine.cpu}</Text>
        </Stack>
        <Stack horizontal horizontalAlign="space-between">
          <Text {...viewLabel}>{engine.engineType === Engine_Type.H2O ? 'GPUs per Node' : 'GPU Units'}</Text>
          <Text {...viewValue}>{engine.gpu}</Text>
        </Stack>
        <Stack horizontal horizontalAlign="space-between">
          <Text {...viewLabel}>{engine.engineType === Engine_Type.H2O ? 'Memory per Node (GiB)' : 'Memory (GiB)'}</Text>
          <Text {...viewValue}>{bytesToGibibytes(engine.memoryBytes)}</Text>
        </Stack>
        {(engine.engineType === Engine_Type.DRIVERLESS_AI || engine.storageBytes) && (
          <Stack horizontal horizontalAlign="space-between">
            <Text {...viewLabel}>
              {engine.engineType === Engine_Type.H2O ? 'Allocated Storage per Node (GiB)' : 'Allocated Storage (GiB)'}
            </Text>
            <Text {...viewValue}>{bytesToGibibytes(engine.storageBytes)}</Text>
          </Stack>
        )}
        {engine.freeDiskSizeBytes && (
          <Stack horizontal horizontalAlign="space-between">
            <Text {...viewLabel}>Free storage (GiB)</Text>
            <Text {...viewValue}>{bytesToGibibytes(engine.freeDiskSizeBytes)}</Text>
          </Stack>
        )}
        <Stack horizontal horizontalAlign="space-between">
          <Text {...viewLabel}>Max Idle Time (Hrs)</Text>
          <Text>{secondsToHours(engine.maxIdleDuration!)}</Text>
        </Stack>
        <Stack horizontal horizontalAlign="space-between">
          <Text {...viewLabel}>Max Uptime (Hrs)</Text>
          <Text>{secondsToHours(engine.maxRunningDuration!)}</Text>
        </Stack>
        <Stack horizontal horizontalAlign="space-between">
          <Text {...viewLabel}>Created At</Text>
          <Text {...viewValue}>{engine.createTime?.toLocaleString()}</Text>
        </Stack>
        <Stack horizontal horizontalAlign="space-between">
          <Text {...viewLabel}>Last Resumed At</Text>
          <Text {...viewValue}>{engine.resumeTime?.toLocaleString()}</Text>
        </Stack>
        {engine.engineType === Engine_Type.DRIVERLESS_AI && (
          <Accordion
            title="Advanced Configuration (config.toml)"
            isClose
            styles={{
              title: { fontWeight: 400 },
              root: { marginTop: 12, marginBottom: 40, height: 'unset !important' },
            }}
          >
            <AdvancedConfiguration
              readOnly={true}
              ref={advancedConfigurationRef}
              config={engine.config || {}}
              onConfigChange={() => null}
            />
          </Accordion>
        )}
      </Stack>
    </Panel>
  );
}
