import { MessageBarType, Stack } from '@fluentui/react';
import {
  Button,
  IconName,
  Item,
  ListCell,
  TooltipHost,
  buttonStylesGhost,
  buttonStylesIcon,
  buttonStylesIconCard,
  itemStylesTag,
  useClassNames,
  useToast,
} from '@h2oai/ui-kit';
import { useCallback, useMemo } from 'react';

import { useUser } from '../../../../utils/hooks';
import { formatError } from '../../../../utils/utils';
import { useWorkspaces } from '../../../Orchestrator/WorkspaceProvider';
import { usersCircleListStyle } from './parts.styles';
import {
  UserCircleProps,
  UsersCircleListClassNames,
  UsersCircleListStyles,
  WorkspaceItemActionsProps,
  WorkspaceSharedWithCellProps,
} from './types';

export const getInitials = (fullName?: string) => {
  if (!fullName) return '';

  const trimmedName = fullName.trim();
  const names = trimmedName.split(' ');

  const firstName = names[0];
  const lastName = names[names.length - 1];

  if (names.length === 1) {
    return `${firstName.charAt(0).toUpperCase()}`;
  }

  return `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`;
};

export const getFirstItemsAndCount = (array: any[]) => {
  const items = array.slice(0, 5);
  const countLeft = array.length - items.length;

  return { items, countLeft };
};

export const UsersCircleList = (props: UserCircleProps) => {
  const { items, countLeft } = useMemo(() => {
    return getFirstItemsAndCount(props.users || []);
  }, [props.users]);
  const classNames = useClassNames<UsersCircleListStyles, UsersCircleListClassNames>(
    'UsersCircleList',
    usersCircleListStyle
  );

  return (
    <Stack horizontal className={classNames.root} verticalAlign="center">
      {items?.map((user) => (
        <div className={classNames.circle} key={user.name}>
          <span>{getInitials(user.displayName)}</span>
        </div>
      ))}
      {countLeft > 0 && <div className={classNames.usersNumber}>+{countLeft}</div>}
    </Stack>
  );
};

export const WorkspaceSharedWithCell = (props: WorkspaceSharedWithCellProps) => {
  const { users, header } = props;

  return (
    <ListCell
      headerText={header}
      text={''}
      onRenderText={() => {
        return (
          <TooltipHost id="users-tooltip" content={users?.map((user) => user.displayName).join(', ')}>
            <UsersCircleList users={users || []} />
          </TooltipHost>
        );
      }}
    />
  );
};

export const WorkspaceItemActions = (props: WorkspaceItemActionsProps) => {
  const { workspace } = props;
  const user = useUser();
  const { deleteWorkspace, undeleteWorkspace, ACTIVE_WORKSPACE_NAME } = useWorkspaces();
  const { addToast, removeAllToasts } = useToast();
  const handleUndeleteWorkspace = useCallback(async (workspaceName: string) => {
    try {
      await undeleteWorkspace(workspaceName);
      removeAllToasts();
    } catch (error) {
      const message = `Failed to undelete workspace: ${formatError(error)}`;
      console.error(message);
      addToast({ messageBarType: MessageBarType.error, message });
    }
  }, []);
  const handleDeleteWorkspace = useCallback(async (workspaceName: string) => {
    try {
      await deleteWorkspace(workspaceName);
      addToast({
        messageBarType: MessageBarType.success,
        message: (
          <Stack>
            <p>Workspace successfully deleted.</p>
            <Stack horizontalAlign="end">
              <Button onClick={() => handleUndeleteWorkspace(workspaceName)} styles={buttonStylesGhost}>
                Undo Delete
              </Button>
            </Stack>
          </Stack>
        ),
      });
    } catch (error) {
      const message = `Failed to delete workspace: ${formatError(error)}`;
      console.error(message);
      addToast({ messageBarType: MessageBarType.error, message });
    }
  }, []);

  return (
    <Stack horizontal tokens={{ childrenGap: 16 }} horizontalAlign="end" verticalAlign="center">
      {workspace.name === ACTIVE_WORKSPACE_NAME && (
        <Item
          data={{
            style: {
              backgroundColor: 'transparent',
              padding: '0px 20px',
              fontSize: 14,
            },
            title: 'Active',
          }}
          labelField="title"
          styleField="style"
          styles={itemStylesTag}
        />
      )}

      <Button
        styles={[
          buttonStylesIcon,
          buttonStylesIconCard,
          { root: { minWidth: '32px', i: { fontSize: '24px', transform: 'rotate(90deg)' } } },
        ]}
        menuIconName={IconName.More}
        menuItems={[
          {
            key: 'workspace-delete',
            text: 'Delete',
            onClick: () => {
              handleDeleteWorkspace(String(workspace.name));
            },
            disabled: !workspace.creator?.includes(user.id),
            'data-test': 'workspace-delete-button',
          },
        ]}
      />
    </Stack>
  );
};
