import { ArtifactComposition } from '@buf/h2oai_mlops-deployment.bufbuild_es/ai/h2o/mlops/deployer/v1/artifact_composition_pb';
import { Security } from '@buf/h2oai_mlops-deployment.bufbuild_es/ai/h2o/mlops/deployer/v1/security_pb';
import { Artifact } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/artifact_pb';
import { Experiment } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/experiment_pb';
import { Operator } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/query_pb';
import { RegisteredModel } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/registered_model_pb';
import { ListRegisteredModelsRequest } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/registered_model_service_pb';
import { RegisteredModelService } from '@buf/h2oai_mlops-storage.connectrpc_es/ai/h2o/mlops/storage/v1/registered_model_service_connect';
import { createPromiseClient } from '@connectrpc/connect';
import { createConnectTransport } from '@connectrpc/connect-web';
import { IStyle, MessageBarType } from '@fluentui/react';
import { IH2OTheme, useClassNames, useTheme, useToast } from '@h2oai/ui-kit';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { FailedToLoadView } from '../../components/FailedToLoadView/FailedToLoadView';
import Header from '../../components/Header/Header';
import { NoItemView } from '../../components/NoItemView/NoItemView';
import { RowHeaderTitle } from '../../components/RowHeaderTitle/RowHeaderTitle';
import WidgetList from '../../components/WidgetList/WidgetList';
import { useCloudPlatformDiscovery } from '../../utils/hooks';
import { ClassNamesFromIStyles } from '../../utils/models';
import { formatError } from '../../utils/utils';
import { ContextMenuIconButton } from '../Orchestrator/Workflows';
import { ENDPOINTS, ROUTES } from './constants';
import PageWrapper from './PageWrapper';
import { useProjects } from './ProjectProvider';

interface IModelsStyles {
  title: IStyle;
}

export type DeploymentOptions = {
  displayName: string;
  description: string;
  entityArtifacts: Artifact[];
  experimentId: string;
  artifactCompositions: ArtifactComposition[];
  selectedArtifactComposition: ArtifactComposition;
  deploymentEnvironmentId: string;
  monitoringEnabled: boolean;
  driftMonitoringEnabled: boolean;
  deployableArtifactTypeName: string;
  artifactProcessorName: string;
  runtimeName: string;
  advanced: {
    toleration: string;
    affinity: string;
  };
  requests: Record<string, string>;
  limits: Record<string, string>;
  kubernetesOptions: {
    replicas: number;
  };
  security?: Security;
};

export type RegisteredModelItem = RegisteredModel & {
  createdTimeLocal: string;
  createdByName: string;
  deleteModel?: () => Promise<void>;
};

const modelsStyles = (theme: IH2OTheme): IModelsStyles => {
  return {
    title: {
      marginTop: 0,
      // TODO: Remove once theme is used somewhere else.
      color: theme.semanticColors?.textPrimary,
    },
  };
};
const columns = [
  {
    key: 'title',
    name: 'Title',
    fieldName: 'description',
    minWidth: 200,
    maxWidth: 800,
    data: {
      headerFieldName: 'displayName',
      listCellProps: {
        onRenderHeader: ({ displayName }: Experiment) => RowHeaderTitle({ title: displayName }),
        iconProps: {
          iconName: 'FolderHorizontal',
        },
      },
    },
  },
  {
    key: 'user',
    name: 'Created by',
    fieldName: 'createdByName',
    minWidth: 150,
    maxWidth: 220,
  },
  {
    key: 'createdAt',
    name: 'Created at',
    fieldName: 'createdTimeLocal',
    minWidth: 150,
    maxWidth: 250,
  },
  {
    key: 'buttons',
    name: '',
    minWidth: 200,
    data: {
      listCellProps: {
        emptyMessage: 'No Description',
        onRenderText: ({ deleteModel }: RegisteredModelItem) => (
          // TODO: Use theme prop for colors.
          <ContextMenuIconButton
            items={[
              {
                key: 'delete',
                text: 'Delete model',
                // TODO: Implement onClickDelete.
                onClick: () => {
                  (async () => {
                    await deleteModel?.();
                  })();
                },
                style: { color: 'var(--h2o-red400)' },
                iconProps: {
                  iconName: 'Delete',
                  style: { color: 'var(--h2o-red400)' },
                },
              },
            ]}
          />
        ),
        styles: {
          root: {
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'end',
          },
        },
      },
    },
  },
];

const Models = () => {
  const theme = useTheme(),
    history = useHistory(),
    classNames = useClassNames<IModelsStyles, ClassNamesFromIStyles<IModelsStyles>>('models', modelsStyles(theme)),
    { addToast } = useToast(),
    { ACTIVE_PROJECT_ID } = useProjects(),
    loadStateRef = React.useRef({
      fetchRegisteredModels: false,
    }),
    [loading, setLoading] = React.useState(true),
    [isLoadingMore, setIsLoadingMore] = React.useState(false),
    [isLoadingSearch, setIsLoadingSearch] = React.useState(false),
    [nextPageToken, setNextPageToken] = React.useState<string>(),
    [registeredModelItems, setRegisteredModelItems] = React.useState<RegisteredModelItem[]>(),
    // TODO: Move to context global to mlops.
    cloudPlatformDiscovery = useCloudPlatformDiscovery(),
    mlopsApiUrl = cloudPlatformDiscovery?.mlopsApiUrl || '',
    storageTransport = createConnectTransport({
      baseUrl: `${mlopsApiUrl}${ENDPOINTS.storage}/`,
    }),
    registeredModelClient = createPromiseClient(RegisteredModelService, storageTransport),
    evaluateLoading = () => {
      if (!loadStateRef.current.fetchRegisteredModels) {
        setLoading(false);
      }
    },
    onAction = () => history.push(`/mlops/projects/${ACTIVE_PROJECT_ID}${ROUTES.MODELS}/add-new`),
    onDeleteModel = async (_modelId: string) => {
      // TODO: Implement delete model.
    },
    fetchRegisteredModels = React.useCallback(
      async (pageToken?: string, filter?: string) => {
        if (!ACTIVE_PROJECT_ID) return;
        loadStateRef.current.fetchRegisteredModels = true;
        if (pageToken) setIsLoadingMore(true);
        else if (filter || filter === `""`) setIsLoadingSearch(true);
        else setLoading(true);
        try {
          const listRegisteredModelsBody = new ListRegisteredModelsRequest(
            filter
              ? {
                  projectId: ACTIVE_PROJECT_ID,
                  paging: {
                    pageSize: 20,
                    pageToken: pageToken ? new TextEncoder().encode(pageToken) : undefined,
                  },
                  filter: {
                    query: {
                      clause: [
                        {
                          propertyConstraint: [
                            {
                              property: {
                                propertyType: {
                                  // TODO: Make filter case insensitive.
                                  value: 'name',
                                  case: 'field',
                                },
                              },
                              operator: Operator.CONTAINS,
                              value: {
                                value: {
                                  case: 'stringValue',
                                  value: filter,
                                },
                              },
                            },
                          ],
                        },
                      ],
                    },
                  },
                }
              : {
                  projectId: ACTIVE_PROJECT_ID,
                  paging: {
                    pageSize: 20,
                    pageToken: pageToken ? new TextEncoder().encode(pageToken) : undefined,
                  },
                }
          );
          const response = await registeredModelClient.listRegisteredModels(listRegisteredModelsBody);
          const registeredModelItems: RegisteredModel[] | undefined = response?.registeredModels;
          if (response && !registeredModelItems) console.error('No registered models found in the response.');
          setNextPageToken(
            response?.paging?.nextPageToken ? new TextDecoder().decode(response.paging.nextPageToken) : undefined
          );
          const newItems: RegisteredModelItem[] | undefined = registeredModelItems?.map(
            (item) =>
              ({
                ...item,
                createdTimeLocal:
                  item.createdTime?.seconds !== undefined
                    ? new Date(Number(item.createdTime.seconds) * 1000).toLocaleString()
                    : '',
                // TODO: Display user name instead of the id.
                createdByName: item.createdBy,
                // TODO: Check if "onDeleteModel" is up to date.
                deleteModel: () => onDeleteModel(item.id),
              } as RegisteredModelItem)
          );
          setRegisteredModelItems((items) => (pageToken ? [...(items || []), ...(newItems || [])] : newItems));
        } catch (err) {
          const message = `Failed to fetch registered models: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
          setRegisteredModelItems(undefined);
        }
        loadStateRef.current.fetchRegisteredModels = false;
        evaluateLoading();
        setIsLoadingMore(false);

        setIsLoadingSearch(false);
      },
      [addToast, ACTIVE_PROJECT_ID]
    ),
    widgetListProps = {
      columns,
      items: registeredModelItems,
      loading: !!loading,
      delayLoader: false,
      isLoadingMore: isLoadingMore,
      onLoadMore: nextPageToken ? () => void fetchRegisteredModels(nextPageToken) : undefined,
      isLoadingSearch,
      searchProps: {
        placeholder: 'Search models',
        // TODO: Check if "value" is correct.
        onSearchChange: (value: string) => void fetchRegisteredModels(undefined, value),
      },
      actionProps: {
        actionIcon: 'Add',
        actionTitle: 'Add model',
        onActionClick: onAction,
      },
      NoItemsContent: NoItemView({
        title: 'Models',
        description: 'There are no models available in this project. Create the first one to begin.',
        actionTitle: 'Add model',
        onActionClick: onAction,
        actionIcon: 'Add',
      }),
      ErrorContent: FailedToLoadView({
        title: 'Failed to load models',
        description: 'Please try again later. If the problem persists, contact our support.',
        actionTitle: 'Retry',
        onActionClick: fetchRegisteredModels,
        actionIcon: 'Refresh',
      }),
    };

  React.useEffect(() => void fetchRegisteredModels(), [fetchRegisteredModels]);

  return (
    <PageWrapper>
      <Header />
      <WidgetList {...widgetListProps} />
      {/* TODO: Remove once classNames is used somewhere. */}
      <div className={classNames.title} style={{ display: 'none' }} />
    </PageWrapper>
  );
};

export default Models;
