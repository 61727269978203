import { IDropdownOption } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import {
  AppBarNavigation,
  BookTimeDialog,
  Button,
  ConfirmDialog,
  IconName,
  getPersonaProps,
  getUserMenuItems,
  useAppNavigationContext,
  useTheme,
} from '@h2oai/ui-kit';
import { FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useHistory } from 'react-router-dom';

import { AppInstance_Status, AppInstance_Visibility } from '../../ai.h2o.cloud.appstore';
import { listServices } from '../../discovery-service/api';
import { useRoles } from '../../pages/Orchestrator/RoleProvider';
import { useWorkspaces } from '../../pages/Orchestrator/WorkspaceProvider';
import { useCloudPlatformDiscovery, useEnv, useInstance } from '../../utils/hooks';
import { HaicAboutModal } from '../HaicAboutModal/HaicAboutModal';
import { signInButtonStyles } from './Navigation.styles';
import type { NavigationConfig } from './types';
import { getNavigationConfig } from './utils';

export const Navigation = () => {
  const { disableWorkspacesDropdown, workspacesDisabled } = useAppNavigationContext();
  const auth = useAuth();
  const env = useEnv();
  const { workspaces, activeWorkspace, activateWorkspace } = useWorkspaces();
  const history = useHistory();
  const cloudPlatformDiscovery = useCloudPlatformDiscovery();
  const { permissions } = useRoles();
  const { getInstancesList } = useInstance();
  const theme = useTheme();

  const [appVersion, setAppVersion] = useState('');
  const [instancePromptHidden, setInstancePromptHidden] = useState(true);
  const [instanceCount, setInstanceCount] = useState<null | number>(null);
  const [navigationConfig, setNavigationConfig] = useState<NavigationConfig>({
    navigationSectionConfig: {},
    userMenuItems: [],
  });
  const [bookTimeHidden, setBookTimeHidden] = useState<boolean>(true);
  const [hiddenAbout, { setFalse: showAbout, setTrue: hideAbout }] = useBoolean(true);

  const workspacesData = useMemo(() => {
    return workspaces
      ? workspaces.map((item) => ({
          id: item.name,
          key: item.name as string | number,
          name: item.displayName,
          text: item.displayName || '',
          title: item.displayName,
          selected: item.name === activeWorkspace?.name,
          data: item,
        }))
      : [];
  }, [workspaces, activeWorkspace]);
  const logout = useCallback(async () => {
    const token = auth.user?.id_token;

    setInstancePromptHidden(true);

    await auth.removeUser();
    await auth.signoutRedirect({ id_token_hint: token });
  }, []);
  const goToMyInstances = useCallback(() => {
    setInstancePromptHidden(true);
    history.push('/instances');
  }, [history, setInstancePromptHidden]);
  const onLogoutClick = useCallback(async () => {
    let count;

    try {
      const instances = await getInstancesList({
        appId: '',
        includeAppDetails: false,
        allUsers: false,
        visibility: AppInstance_Visibility.VISIBILITY_UNSPECIFIED,
      });

      count = instances.filter((instance) =>
        [
          AppInstance_Status.DEPLOYED,
          AppInstance_Status.PENDING,
          AppInstance_Status.STATUS_UNSPECIFIED,
          AppInstance_Status.STATUS_UNKNOWN,
        ].includes(instance.status)
      ).length;
    } catch (err) {
      console.error(err);
      count = null;
    }

    setInstanceCount(count);

    count !== 0 ? setInstancePromptHidden(false) : await logout();
  }, [instanceCount, logout, getInstancesList]);
  const handleWorkspaceChange = useCallback(
    (_event: FormEvent<HTMLDivElement>, option: IDropdownOption) => {
      activateWorkspace(option.data.name);
    },
    [activateWorkspace]
  );

  const headerClick = () => history.push('/');

  useEffect(() => {
    const setVersion = async () => {
      const servicesList = await listServices({ pageSize: 1000 });

      setAppVersion(servicesList?.filter((service) => service.name === 'services/appstore')[0].version || '');
    };

    setVersion();

    if (env?.menu) {
      const userMenuItems = getUserMenuItems(env.menu || {}, { hasMenu: false }, onLogoutClick, history, showAbout);
      const navigationConfig = getNavigationConfig(
        userMenuItems,
        env,
        cloudPlatformDiscovery,
        permissions,
        appVersion,
        workspacesDisabled
      );

      setNavigationConfig(navigationConfig);
    }
  }, [env?.menu, cloudPlatformDiscovery, appVersion, workspacesDisabled, permissions]);

  useEffect(() => {
    // Disable the Workspaces dropdown by default
    // Can be enabled from any place by calling enableWorkspacesDropdown();
    disableWorkspacesDropdown();
  }, []);

  return (
    <>
      <AppBarNavigation
        logoImageProps={{ src: '/logo.svg', alt: '' }}
        personaProps={auth.user?.id_token ? getPersonaProps(env?.menu || {}) : undefined}
        onLogoClick={headerClick}
        onTitleClick={headerClick}
        userMenuItems={
          auth.user?.id_token
            ? getUserMenuItems(env?.menu || {}, { hasMenu: false }, onLogoutClick, history, showAbout)
            : undefined
        }
        navigationConfig={navigationConfig.navigationSectionConfig}
        workspaces={workspacesData}
        onWorkspaceChange={handleWorkspaceChange}
        selectedWorkspaceKey={activeWorkspace?.name}
      >
        {!auth.user?.id_token && (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              text={'Sign in'}
              onClick={() => auth.signinRedirect()}
              styles={signInButtonStyles(theme)}
              iconName={IconName.ChevronRight}
            />
          </div>
        )}
      </AppBarNavigation>

      <ConfirmDialog
        hidden={instancePromptHidden}
        title="Terminate instances before logout?"
        content={
          <>
            You have <b>{instanceCount ?? 'Unknown'}</b> running app instance{instanceCount === 1 ? '' : 's'} that are
            consuming cluster resources, do you wish to review and terminate them before logging out?
          </>
        }
        confirmationButtonText="No, logout"
        dismissalButtonText="Yes, go to My Instances"
        onDismiss={goToMyInstances}
        onConfirm={logout}
      />
      <HaicAboutModal hidden={hiddenAbout} onDismiss={hideAbout} />
      <BookTimeDialog url={env?.menu?.bookTimeLink} onDismiss={() => setBookTimeHidden(true)} hidden={bookTimeHidden} />
    </>
  );
};
