import { PanelType } from '@fluentui/react';
import { Panel } from '@h2oai/ui-kit';

import { AddEditModelForm } from '../../../components/AdminSettings/components/AddEditModelForm/AddEditModelForm';
import { stylesPanel } from '../../../components/AIEnginesPage/components/AIEMPanel/AIEMPanel';
import { SecureStoreEntitiesMap } from '../../../secure-store/entity/services';
import { SecureStoreEntityType } from '../../../secure-store/entity/types';
import { SecureStoreEditPanelProps, SecureStoreEntityModel } from './types';

export const SecureStoreEditPanel = (props: SecureStoreEditPanelProps) => {
  const { panelTitle, onDismiss, isCreate, onSave, entity, model } = props;

  if (!model) return <></>;

  return (
    <Panel
      isLightDismiss
      customWidth="500px"
      headerText={panelTitle}
      isFooterAtBottom
      isOpen={true}
      onDismiss={onDismiss}
      type={PanelType.custom}
      styles={stylesPanel}
    >
      <AddEditModelForm<SecureStoreEntityModel, SecureStoreEntityType>
        requestPath={props.requestPath}
        entitiesMap={SecureStoreEntitiesMap}
        entity={entity}
        model={model}
        isCreate={isCreate}
        onSave={onSave}
        onDismiss={onDismiss}
      />
    </Panel>
  );
};
