import { Separator, Stack } from '@fluentui/react';
import {
  IKeyValuePairEditorClassnames,
  IKeyValuePairEditorStyles,
  keyValuePairEditorStyles,
  useClassNames,
} from '@h2oai/ui-kit';
import { useCallback, useEffect, useState } from 'react';

import { MemberListItem } from './MemberListItem';
import { membersListStyles } from './styles';
import { ManageMembersProps, UserWithPermissions } from './types';

export const ManageMembers = (props: ManageMembersProps) => {
  const [usersInList, setUsersInList] = useState<UserWithPermissions[]>([]);
  const classNames = useClassNames<IKeyValuePairEditorStyles, IKeyValuePairEditorClassnames>(
    'MembersList',
    keyValuePairEditorStyles,
    membersListStyles
  );

  const addUserToList = useCallback(
    (user: UserWithPermissions) => setUsersInList([user, ...usersInList]),
    [usersInList, setUsersInList]
  );
  const updateUserInList = useCallback(
    (user: UserWithPermissions) => {
      const updatedList = usersInList.map((userItem) => {
        if (userItem.name === user.name) {
          return user;
        } else {
          return userItem;
        }
      });
      setUsersInList(updatedList);
    },
    [usersInList, setUsersInList]
  );
  const removeUserFromList = useCallback(
    (userName: string) => {
      setUsersInList([...usersInList.filter((userItem) => userItem.name !== userName)]);
    },
    [usersInList, setUsersInList]
  );

  useEffect(() => {
    props.onMembersListChange(usersInList);
  }, [usersInList, props.onMembersListChange]);

  return (
    <Stack className={classNames.root}>
      <Stack horizontal horizontalAlign={'space-between'} className={classNames.header}>
        <div className={classNames.keyColumn}>
          <span>Member Name</span>
        </div>
        <div className={classNames.valueColumn}>
          <span>Permission</span>
        </div>
        <div className={classNames.buttonColumn}>
          <span>Actions</span>
        </div>
      </Stack>
      <Stack>
        <MemberListItem addUser={addUserToList} removeUser={removeUserFromList} usersInList={usersInList} />

        {usersInList.length > 0 && <Separator styles={{ root: { margin: '16px 0' } }}>Chosen members</Separator>}

        {usersInList.map((user) => (
          <MemberListItem
            key={user.name}
            prepopulatedUser={user}
            prepopulatedPermissions={user.permissions}
            addUser={addUserToList}
            removeUser={removeUserFromList}
            usersInList={usersInList}
            updateUserWithPermissions={updateUserInList}
          />
        ))}
      </Stack>
    </Stack>
  );
};
